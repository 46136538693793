import { ElectionPriority, ElectionStatus, ElectionStatusName, MaxDmMessageLength } from './const'

export const ErrorCode = {
  BadUserInput: 'BAD_USER_INPUT',
  ForbiddenError: 'FORBIDDEN_ERROR',
  Internal: 'INTERNAL',
} as const
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode]

export const AppCode = {
  e1: '1',
  e2: '2',
  e3: '3',
  e4: '4',
  e5: '5',
  e6: '6',
  e7: '7',
  e8: '8',
  e9: '9',
  e10: '10',
  e11: '11',
  e12: '12',
  e13: '13',
  e14: '14',
  e15: '15',
  e16: '16',
  e17: '17',
  e18: '18',
  e19: '19',
  e20: '20',
  e21: '21',
  e22: '22',
  e23: '23',
  e24: '24',
  e25: '25',
  e26: '26',
  e27: '27',
  e29: '29',
  e30: '30',
  e31: '31',
  e32: '32',
  e33: '33',
  e34: '34',
  e35: '35',
  e36: '36',
  e37: '37',
  e38: '38',
  e39: '39',
  e40: '40',
  e41: '41',
  e42: '42',
  e43: '43',
  e44: '44',
  e45: '45',
  e46: '46',
  e47: '47',
  e48: '48',
  e49: '49',
  e50: '50',
  e51: '51',
  e52: '52',
  e53: '53',
  e54: '54',
  e55: '55',
  e56: '56',
  e57: '57',
  e58: '58',
  e59: '59',
  e60: '60',
  e61: '61',
  e62: '62',
  e63: '63',
  e64: '64',
  e65: '65',
  e66: '66',
  e67: '67',
  e68: '68',
  e69: '69',
  e70: '70',
  e71: '71',
  e72: '72',
  e73: '73',
  e74: '74',
  e75: '75',
  e76: '76',
  e77: '77',
  e78: '78',
  e79: '79',
  e80: '80',
  e81: '81',
  e82: '82',
  e83: '83',
  e84: '84',
  e85: '85',
  e86: '86',
  e87: '87',
  e88: '88',
  e89: '89',
  e90: '90',
  e91: '91',
  e92: '92',
  e93: '93',
  e94: '94',
  e95: '95',
  e96: '96',
  e97: '97',
  e98: '98',
  e99: '99',
  e100: '100',
  e101: '101',
  e102: '102',
  e103: '103',
  e104: '104',
  e105: '105',
  e106: '106',
  e107: '107',
  e108: '108',
  e109: '109',
  e110: '110',
  e111: '111',
  e112: '112',
  e113: '113',
  e114: '114',
  e115: '115',
  e116: '116',
  e117: '117',
  e118: '118',
  e119: '119',
  e120: '120',
  e121: '121',
  e122: '122',
  e123: '123',
  e124: '124',
  e125: '125',
  e126: '126',
  e127: '127',
  e128: '128',
  e129: '129',
  e130: '130',
  e131: '131',
  e132: '132',
  e133: '133',
  e134: '134',
  e135: '135',
  e136: '136',
  e137: '137',
  e138: '138',
  e139: '139',
  e140: '140',
  e141: '141',
  e142: '142',
  e143: '143',
  e144: '144',
  e145: '145',
  e146: '146',
  e147: '147',
  e148: '148',
  e149: '149',
  e150: '150',
  e151: '151',
  e152: '152',
  e153: '153',
  e154: '154',
  e155: '155',
  e156: '156',
  e157: '157',
  e158: '158',
  e159: '159',
  e160: '160',
  e161: '161',
  e162: '162',
  needToReplaceDoublework1: 'needToReplaceDoublework1',
  needToReplaceDoublework2: 'needToReplaceDoublework2',
  needToReplaceDoubleworkSuspend: 'needToReplaceDoubleworkSuspend',
  bulkExportDoubleworks1: 'bulkExportDoubleworks1',
  needToReplaceDoubleworkCreate1: 'needToReplaceDoubleworkCreate1',
  needToReplaceDoubleworkCreate2: 'needToReplaceDoubleworkCreate2',
  needToReplaceDoubleworkCreate3: 'needToReplaceDoubleworkCreate3',
  needToReplaceDoubleworkCreate4: 'needToReplaceDoubleworkCreate4',
  needToReplaceDoubleworkCreate5: 'needToReplaceDoubleworkCreate5',
  needToReplaceDoubleworkCreate6: 'needToReplaceDoubleworkCreate6',
  needToReplaceDoubleworkCreate7: 'needToReplaceDoubleworkCreate7',
  needToReplaceDoubleworkCreate8: 'needToReplaceDoubleworkCreate8',
  needToReplaceDoubleworkCreate9: 'needToReplaceDoubleworkCreate9',
  needToReplaceDoubleworkCreate10: 'needToReplaceDoubleworkCreate10',
  needToReplaceDoubleworkCreate11: 'needToReplaceDoubleworkCreate11',
  needToReplaceDoubleworkCreate12: 'needToReplaceDoubleworkCreate12',
  needToReplaceDoubleworkCreate13: 'needToReplaceDoubleworkCreate13',
  needToReplaceDoubleworkCreate14: 'needToReplaceDoubleworkCreate14',
  needToReplaceDoubleworkCreate15: 'needToReplaceDoubleworkCreate15',
  updateDoublework1: 'updateDoublework1',
  updateDoublework2: 'updateDoublework2',
  updateDoublework3: 'updateDoublework3',
  updateDoublework4: 'updateDoublework4',
  updateDoublework5: 'updateDoublework5',
  updateDoublework6: 'updateDoublework6',
  doubleworkNeedToVideoCompleted: 'doubleworkNeedToVideoCompleted',
  needToReplaceCreateDoubleworkEntry1: 'needToReplaceCreateDoubleworkEntry1',
  needToReplaceCreateDoubleworkEntry2: 'needToReplaceCreateDoubleworkEntry2',
  needToReplaceCreateDoubleworkEntry3: 'needToReplaceCreateDoubleworkEntry3',
  needToReplaceCreateDoubleworkEntry4: 'needToReplaceCreateDoubleworkEntry4',
  needToReplaceCreateDoubleworkEntry5: 'needToReplaceCreateDoubleworkEntry5',
  needToReplaceCreateDoubleworkEntry6: 'needToReplaceCreateDoubleworkEntry6',
  needToReplaceCreateDoubleworkEntry7: 'needToReplaceCreateDoubleworkEntry7',
  needToReplaceCreateDoubleworkEntry8: 'needToReplaceCreateDoubleworkEntry8',
  needToReplaceCreateDoubleworkEntry9: 'needToReplaceCreateDoubleworkEntry9',
  createDoubleworkEntry10: 'createDoubleworkEntry10',
  needToReplaceDeleteDoubleworkEntry1: 'needToReplaceDeleteDoubleworkEntry1',
  needToReplaceGetDateFromYearString1: 'needToReplaceGetDateFromYearString1',
  needToReplacebulkExportDoubleworkEntries1: 'needToReplacebulkExportDoubleworkEntries1',
  needToReplaceUpdateEntriesFlowStep1: 'needToReplaceUpdateEntriesFlowStep1',
  needToReplaceUpdateEntriesFlowStep2: 'needToReplaceUpdateEntriesFlowStep2',
  needToReplaceUpdateEntriesFlowStep4: 'needToReplaceUpdateEntriesFlowStep4',
  needToReplaceUpdateEntriesFlowStep5: 'needToReplaceUpdateEntriesFlowStep5',
  needToReplaceUpdateEntriesFlowStep6: 'needToReplaceUpdateEntriesFlowStep6',
  needToReplaceUpdateEntriesFlowStep7: 'needToReplaceUpdateEntriesFlowStep7',
  needToReplaceUpdateEntriesFlowStep8: 'needToReplaceUpdateEntriesFlowStep8',
  needToReplaceUpdateEntriesFlowStep9: 'needToReplaceUpdateEntriesFlowStep9',
  needToReplaceUpdateEntriesFlowStep10: 'needToReplaceUpdateEntriesFlowStep10',
  needToReplaceUpdateEntriesFlowStep11: 'needToReplaceUpdateEntriesFlowStep11',
  needToReplaceUpdateEntriesFlowStep12: 'needToReplaceUpdateEntriesFlowStep12',
  needToReplaceUpdateEntriesFlowStep13: 'needToReplaceUpdateEntriesFlowStep13',
  needToReplaceUpdateEntriesFlowStep14: 'needToReplaceUpdateEntriesFlowStep14',
  createDoubleworkEntryNote1: 'createDoubleworkEntryNote1',
  updateDoubleworkEntryIsDecided1: 'updateDoubleworkEntryIsDecided1',
  updateDoubleworkEntryIsDecided2: 'updateDoubleworkEntryIsDecided2',
  needToReplaceAssignSelector1: 'needToReplaceAssignSelector1',
  needToReplaceAssignSelector2: 'needToReplaceAssignSelector2',
  needToReplaceAssignSelector3: 'needToReplaceAssignSelector3',
  needToReplaceAssignSelector4: 'needToReplaceAssignSelector4',
  needToReplaceAssignSelector5: 'needToReplaceAssignSelector5',
  needToReplacebulkCreateDoubleworkEntryMessages1: 'needToReplacebulkCreateDoubleworkEntryMessages1',
  needToReplaceCreateDoubleworkInterviewSchedule1: 'needToReplaceCreateDoubleworkInterviewSchedule1',
  needToReplaceCreateDoubleworkInterviewSchedule2: 'needToReplaceCreateDoubleworkInterviewSchedule2',
  needToReplaceCreateDoubleworkInterviewSchedule3: 'needToReplaceCreateDoubleworkInterviewSchedule3',
  needToReplaceCreateDoubleworkInterviewSchedule4: 'needToReplaceCreateDoubleworkInterviewSchedule4',
  needToReplaceSelectInterviewScheduleDate1: 'needToReplaceSelectInterviewScheduleDate1',
  needToReplaceSelectInterviewScheduleDate2: 'needToReplaceSelectInterviewScheduleDate2',
  needToReplaceSelectInterviewScheduleDate3: 'needToReplaceSelectInterviewScheduleDate3',
  needToReplaceSelectInterviewScheduleDate4: 'needToReplaceSelectInterviewScheduleDate4',
  doubleworkEntryMessages1: 'doubleworkEntryMessages1',
  doubleworkEntryMessages2: 'doubleworkEntryMessages2',
  updateDoubleworkEntryProcedures1: 'updateDoubleworkEntryProcedures1',
  updateDoubleworkEntryProcedures2: 'updateDoubleworkEntryProcedures2',
  updateDoubleworkEntryProcedures3: 'updateDoubleworkEntryProcedures3',
  updateDoubleworkEntryProcedures4: 'updateDoubleworkEntryProcedures4',
  needToReplaceCheckCanEntry1: 'needToReplaceCheckCanEntry1',
  needToReplaceCheckCanEntry2: 'needToReplaceCheckCanEntry2',
  doubleworkCheckCanEntry: 'doubleworkCheckCanEntry',
  doubleworkEntryNeedToVideoCompleted: 'doubleworkEntryNeedToVideoCompleted',
  e163: '163',
  e164: '164',
  e165: '165',
  e166: '166',
  e167: '167',
  e168: '168',
  e169: '169',
  e170: '170',
  e171: '171',
  e172: '172',
  e173: '173',
  e174: '174',
  e175: '175',
  e176: '176',
  e177: '177',
  e178: '178',
  e179: '179',
  e180: '180',
  e181: '181',
  e182: '182',
  e183: '183',
  e184: '184',
  e185: '185',
  e186: '186',
  e187: '187',
  e188: '188',
  e189: '189',
  e190: '190',
  e191: '191',
  e192: '192',
  e193: '193',
  e194: '194',
  needToReplaceCrm191_001: 'needToReplaceCrm191_001',
  e195: '195',
  updateDoubleworkEntryMonitoring1: 'updateDoubleworkEntryMonitoring1',
  updateDoubleworkEntryMonitoring2: 'updateDoubleworkEntryMonitoring2',
  updateDoubleworkEntryMonitoring3: 'updateDoubleworkEntryMonitoring3',
  updateDoubleworkEntryDialogClosedUserId1: 'updateDoubleworkEntryDialogClosedUserId1',
  updateDoubleworkEntryDialogClosedUserId2: 'updateDoubleworkEntryDialogClosedUserId2',
  uniqApplicationDocumentNumber: 'uniqApplicationDocumentNumber',
  updateUserResume1: 'updateUserResume1',
  updateUserResume2: 'updateUserResume2',
  updateUserResume3: 'updateUserResume3',
  updateUserResume4: 'updateUserResume4',
  updateUserResume5: 'updateUserResume5',
  updateUserResume6: 'updateUserResume6',
  updateUserResume7: 'updateUserResume7',
  updateUserResume8: 'updateUserResume8',
  updateUserResume9: 'updateUserResume9',
  updateUserResume10: 'updateUserResume10',
  updateUserResume11: 'updateUserResume11',
  updateUserResume12: 'updateUserResume12',
  updateUserResume13: 'updateUserResume13',
  updateUserResume14: 'updateUserResume14',
  updateUserResume15: 'updateUserResume15',
  createOrUpdateUserResumeCareer1: 'createOrUpdateUserResumeCareer1',
  createOrUpdateUserResumeCareer2: 'createOrUpdateUserResumeCareer2',
  createOrUpdateUserResumeCareer3: 'createOrUpdateUserResumeCareer3',
  createOrUpdateUserResumeCareer4: 'createOrUpdateUserResumeCareer4',
  deleteUserResumeCareer1: 'userResumeCareer1',
  deleteUserResumeCareer2: 'userResumeCareer2',
  deleteUserResumeCareer3: 'userResumeCareer3',
  userResumeCareerActivity1: 'userResumeCareerActivity1',
  userResumeCareerActivity2: 'userResumeCareerActivity2',
  userResumeCareerActivity3: 'userResumeCareerActivity3',
  userResumeCareerActivity4: 'userResumeCareerActivity4',
  userResumeCareerActivity5: 'userResumeCareerActivity5',
  userResumeCareerActivity6: 'userResumeCareerActivity6',
  deleteSkills1: 'deleteSkills1',
  deleteSkills2: 'deleteSkills2',
  updateUserResumeSkill1: 'updateUserResumeSkill1',
  updateUserResumeSkill2: 'updateUserResumeSkill2',
  createOrUpdateUserResumeAchievement1: 'createOrUpdateUserResumeAchievement1',
  createOrUpdateUserResumeAchievement2: 'createOrUpdateUserResumeAchievement2',
  deleteUserResumeAchievement1: 'deleteUserResumeAchievement1',
  userResumeQualificationGrade1: 'userResumeQualificationGrade1',
  userResumeQualificationGrade2: 'userResumeQualificationGrade2',
  userResumeQualificationGrade3: 'userResumeQualificationGrade3',
  userResumeQualificationGrade4: 'userResumeQualificationGrade4',
  userResumeQualificationGrade5: 'userResumeQualificationGrade5',
  updateWorkshopEntryWorksheet1: 'updateWorkshopEntryWorksheet1',
  getFilterUserResumes1: 'getFilterUserResumes1',
  getFilterUserResumes2: 'getFilterUserResumes2',
  careerScoutsForbidden: 'careerScoutsForbidden',
  suspendCareerScouts: 'suspendCareerScouts',
  deleteCareerScouts1: 'deleteCareerScouts1',
  deleteCareerScouts2: 'deleteCareerScouts2',
  createCareerScout1: 'createCareerScout1',
  createCareerScout2: 'createCareerScout2',
  createCareerScout3: 'createCareerScout3',
  updateCareerScout1: 'updateCareerScout1',
  updateCareerScout2: 'updateCareerScout2',
  updateCareerScout3: 'updateCareerScout3',
  updateCareerScout4: 'updateCareerScout4',
  markAsReadCareerScoutOfferMessage1: 'markAsReadCareerScoutOfferMessage1',
  toggleCareerScoutOfferSenderUserFlag1: 'toggleCareerScoutOfferSenderFlag1',
  toggleCareerScoutOfferRecipientUserFlag1: 'toggleCareerScoutOfferRecipientUserFlag1',
  deleteCareerScoutOfferMessage1: 'deleteCareerScoutOfferMessage1',
  createOrUpdateCareerScoutOfferAndMessage1: 'createOrUpdateCareerScoutOfferAndMessage1',
  createOrUpdateCareerScoutOfferAndMessage2: 'createOrUpdateCareerScoutOfferAndMessage2',
  createOrUpdateCareerScoutOfferAndMessage3: 'createOrUpdateCareerScoutOfferAndMessage3',
  createOrUpdateCareerScoutOfferAndMessage4: 'createOrUpdateCareerScoutOfferAndMessage4',
  createOrUpdateCareerScoutOfferAndMessage5: 'createOrUpdateCareerScoutOfferAndMessage5',
  checkCanOffer1: 'checkCanOffer1',
  checkCanOffer2: 'checkCanOffer2',
  checkCanOffer3: 'checkCanOffer3',
  checkCanOffer4: 'checkCanOffer4',
  checkCanOffer5: 'checkCanOffer5',
  checkCanOffer6: 'checkCanOffer6',
  checkCanOffer7: 'checkCanOffer7',
  checkCanOffer8: 'checkCanOffer8',
  checkCanOffer9: 'checkCanOffer9',
  checkCanOffer10: 'checkCanOffer10',
  checkCanOffer11: 'checkCanOffer11',
  checkCanOffer12: 'checkCanOffer12',
  checkCanOffer13: 'checkCanOffer13',
  updateUserResumesScoutSetting1: 'updateUserResumesScoutSetting1',
  updateUserResumesScoutSetting2: 'updateUserResumesScoutSetting2',
  updateUserResumesScoutSetting3: 'updateUserResumesScoutSetting3',
  careerScoutTemplate1: 'careerScoutTemplate1',
  careerScoutTemplate2: 'careerScoutTemplate2',
  careerScoutTemplate3: 'careerScoutTemplate3',
  careerScoutTemplate4: 'careerScoutTemplate4',
  createCareerScoutEntry1: 'createCareerScoutEntry1',
  createCareerScoutEntry2: 'createCareerScoutEntry2',
  createCareerScoutEntry3: 'createCareerScoutEntry3',
  careerScoutCheckCanEntry2: 'careerScoutCheckCanEntry2',
  careerScoutCheckCanEntry3: 'careerScoutCheckCanEntry3',
  careerScoutCheckCanEntry4: 'careerScoutCheckCanEntry4',
  careerScoutCheckCanEntry5: 'careerScoutCheckCanEntry5',
  careerScoutCheckCanEntry6: 'careerScoutCheckCanEntry6',
  careerScoutCheckCanEntry7: 'careerScoutCheckCanEntry7',
  careerScoutCheckCanEntry8: 'careerScoutCheckCanEntry8',
  careerScoutCheckCanEntry9: 'careerScoutCheckCanEntry9',
  deleteCareerScoutEntries1: 'deleteCareerScoutEntries1',
  positionCheckCanEntry1: 'positionCheckCanEntry1',
  careerScoutEntryForbidden: 'careerScoutEntryForbidden',
  updateCareerScoutEntriesFlowStep1: 'updateCareerScoutEntriesFlowStep1',
  updateCareerScoutEntriesFlowStep2: 'updateCareerScoutEntriesFlowStep2',
  updateCareerScoutEntriesFlowStep3: 'updateCareerScoutEntriesFlowStep3',
  updateCareerScoutEntriesFlowStep4: 'updateCareerScoutEntriesFlowStep4',
  updateCareerScoutEntriesFlowStep5: 'updateCareerScoutEntriesFlowStep5',
  bulkExportCareerScoutEntries1: 'bulkExportCareerScoutEntries1',
  bulkExportCareerScoutEntries2: 'bulkExportCareerScoutEntries2',
  bulkExportCareerScoutEntries3: 'bulkExportCareerScoutEntries3',
  badParameter: 'badParameter',
  updateAccountAdminUsers1: 'updateAccountAdminUsers1',
  updateAccountAdminUsers2: 'updateAccountAdminUsers2',
  createOrUpdateOrganizationAdminUsers1: 'createOrUpdateOrganizationAdminUsers1',
  createOrUpdateOrganizationAdminUsers2: 'createOrUpdateOrganizationAdminUsers2',
  createOrUpdateOrganizationAdminUsers3: 'createOrUpdateOrganizationAdminUsers3',
  createOrUpdateOrganizationAdminUsers4: 'createOrUpdateOrganizationAdminUsers4',
  bulkExportOrganizationAdminUsers1: 'bulkExportOrganizationAdminUsers1',
  userLoadLock: 'UserLoadLock',
  incomeDetail1: 'incomeDetail1',
  incomeDetail2: 'incomeDetail2',
  autoAssignWorkshopEntriesGroup1: 'autoAssignWorkshopEntriesGroup1',
  autoAssignWorkshopEntriesGroup2: 'autoAssignWorkshopEntriesGroup2',
  autoAssignWorkshopEntriesGroup3: 'autoAssignWorkshopEntriesGroup3',
  autoAssignWorkshopEntriesGroup4: 'autoAssignWorkshopEntriesGroup4',
  e196: '196',
} as const
export type AppCode = typeof AppCode[keyof typeof AppCode]

// eslint-disable-next-line prettier/prettier
export const AppErrors: Array<{ appCode: AppCode, code: ErrorCode, message: string, clientMessage: string }> = [
  {
    appCode: AppCode.e1,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みのポジションです',
    clientMessage: 'エントリー済みのポジションです',
  },
  {
    appCode: AppCode.e2,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みのポジションは削除できません',
    clientMessage: 'エントリー済みのポジションは削除できません',
  },
  {
    appCode: AppCode.e3,
    code: ErrorCode.BadUserInput,
    message: 'テンプレート名が重複しています',
    clientMessage: 'テンプレート名が重複しています',
  },
  {
    appCode: AppCode.e4,
    code: ErrorCode.ForbiddenError,
    message: 'テンプレート更新権限がありません',
    clientMessage: 'テンプレート更新権限がありません',
  },
  {
    appCode: AppCode.e5,
    code: ErrorCode.ForbiddenError,
    message: '無効なtemplateIdが含まれています',
    clientMessage: '削除権限の無いテンプレートが含まれています',
  },
  {
    appCode: AppCode.e6,
    code: ErrorCode.ForbiddenError,
    message: 'DMテンプレート作成権限がありません',
    clientMessage: 'DMテンプレート作成権限がありません',
  },
  {
    appCode: AppCode.e7,
    code: ErrorCode.BadUserInput,
    message: 'ファイルの形式が正しくありません',
    clientMessage: 'ファイルの形式が正しくありません',
  },
  {
    appCode: AppCode.e8,
    code: ErrorCode.BadUserInput,
    message: '相談カテゴリが存在しません',
    clientMessage: '相談カテゴリが存在しません',
  },
  {
    appCode: AppCode.e9,
    code: ErrorCode.BadUserInput,
    message: '権限のないOrganizationです',
    clientMessage: '権限のないOrganizationです',
  },
  {
    appCode: AppCode.e10,
    code: ErrorCode.BadUserInput,
    message: 'エントリー情報がありません',
    clientMessage: 'エントリー情報がありません',
  },
  {
    appCode: AppCode.e11,
    code: ErrorCode.BadUserInput,
    message: 'メッセージの作成権限がありません',
    clientMessage: 'メッセージの作成権限がありません',
  },
  {
    appCode: AppCode.e12,
    code: ErrorCode.BadUserInput,
    message: 'before,after,selfを指定する場合は、いずれか1つを指定してください',
    clientMessage: 'before,after,selfを指定する場合は、いずれか1つを指定してください',
  },
  {
    appCode: AppCode.e13,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.e14,
    code: ErrorCode.BadUserInput,
    message: 'EntryMessage作成権限がないデータが含まれています',
    clientMessage: 'EntryMessage作成権限がないデータが含まれています',
  },
  {
    appCode: AppCode.e15,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが未指定です',
    clientMessage: '誓約バージョンが未指定です',
  },
  {
    appCode: AppCode.e16,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが異なります',
    clientMessage: '誓約バージョンが異なります',
  },
  {
    appCode: AppCode.e17,
    code: ErrorCode.BadUserInput,
    message: 'エントリーの参照権限がありません。',
    clientMessage: 'エントリーの参照権限がありません。',
  },
  {
    appCode: AppCode.e18,
    code: ErrorCode.BadUserInput,
    message: '無効なエントリーが含まれています',
    clientMessage: '無効なエントリーが含まれています',
  },
  {
    appCode: AppCode.e19,
    code: ErrorCode.BadUserInput,
    message: '無効なエントリーです',
    clientMessage: '無効なエントリーです',
  },
  {
    appCode: AppCode.e20,
    code: ErrorCode.BadUserInput,
    message: '辞退理由を選択してください',
    clientMessage: '辞退理由を選択してください',
  },
  {
    appCode: AppCode.e21,
    code: ErrorCode.BadUserInput,
    message: '有効な辞退理由を選択してください',
    clientMessage: '有効な辞退理由を選択してください',
  },
  {
    appCode: AppCode.e22,
    code: ErrorCode.BadUserInput,
    message: '辞退コメントを入力してください',
    clientMessage: '辞退コメントを入力してください',
  },
  {
    appCode: AppCode.e23,
    code: ErrorCode.BadUserInput,
    message: 'エントリーの編集権限がありません',
    clientMessage: 'エントリーの編集権限がありません',
  },
  {
    appCode: AppCode.e24,
    code: ErrorCode.BadUserInput,
    message: '選考ステータスを変更できないエントリー情報です',
    clientMessage: '選考ステータスを変更できないエントリー情報です',
  },
  {
    appCode: AppCode.e25,
    code: ErrorCode.BadUserInput,
    message: '変更先の選考ステップが存在しません',
    clientMessage: '変更先の選考ステップが存在しません',
  },
  {
    appCode: AppCode.e26,
    code: ErrorCode.BadUserInput,
    message: '異なるフローへの選考ステップ変更が含まれています',
    clientMessage: '異なるフローへの選考ステップ変更が含まれています',
  },
  {
    appCode: AppCode.e27,
    code: ErrorCode.BadUserInput,
    message: '面接が完了していないエントリーが含まれています',
    clientMessage: '面接が完了していないエントリーが含まれています',
  },
  {
    appCode: AppCode.e29,
    code: ErrorCode.BadUserInput,
    message: 'エントリーしたユーザーしか変更できない選考ステップが含まれています',
    clientMessage: 'エントリーしたユーザーしか変更できない選考ステップが含まれています',
  },
  {
    appCode: AppCode.e30,
    code: ErrorCode.BadUserInput,
    message: 'グループ人事しか変更できない選考ステップが含まれています',
    clientMessage: 'グループ人事しか変更できない選考ステップが含まれています',
  },
  {
    appCode: AppCode.e31,
    code: ErrorCode.BadUserInput,
    message: 'ポジションの応募期間外です。',
    clientMessage: 'ポジションの応募期間外です。',
  },
  {
    appCode: AppCode.e32,
    code: ErrorCode.BadUserInput,
    message: '公開期間の開始と終了が逆転しています。',
    clientMessage: '公開期間の開始と終了が逆転しています。',
  },
  {
    appCode: AppCode.e33,
    code: ErrorCode.BadUserInput,
    message: '応募期間の開始と終了が逆転しています。',
    clientMessage: '応募期間の開始と終了が逆転しています。',
  },
  {
    appCode: AppCode.e34,
    code: ErrorCode.BadUserInput,
    message: '期間の開始と終了が逆転しています。',
    clientMessage: '期間の開始と終了が逆転しています。',
  },
  {
    appCode: AppCode.e35,
    code: ErrorCode.BadUserInput,
    message: 'ポジション更新権限がありません',
    clientMessage: 'ポジション更新権限がありません',
  },
  {
    appCode: AppCode.e36,
    code: ErrorCode.BadUserInput,
    message: '承認済みポジションの更新権限がありません',
    clientMessage: '承認済みポジションの更新権限がありません',
  },
  {
    appCode: AppCode.e37,
    code: ErrorCode.BadUserInput,
    message: 'ポジション作成権限がありません',
    clientMessage: 'ポジション作成権限がありません',
  },
  {
    appCode: AppCode.e38,
    code: ErrorCode.BadUserInput,
    message: '承認済みポジションのstatusは変更できません',
    clientMessage: '承認済みポジションのstatusは変更できません',
  },
  {
    appCode: AppCode.e39,
    code: ErrorCode.BadUserInput,
    message: '無効なポジションが含まれています',
    clientMessage: '無効なポジションが含まれています',
  },
  {
    appCode: AppCode.e40,
    code: ErrorCode.BadUserInput,
    message: '無効なエントリーが含まれています',
    clientMessage: '無効なエントリーが含まれています',
  },
  {
    appCode: AppCode.e41,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e42,
    code: ErrorCode.BadUserInput,
    message: '編集権限がありません',
    clientMessage: '編集権限がありません',
  },
  {
    appCode: AppCode.e43,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e44,
    code: ErrorCode.BadUserInput,
    message: '編集権限がありません',
    clientMessage: '編集権限がありません',
  },
  {
    appCode: AppCode.e45,
    code: ErrorCode.BadUserInput,
    message: '一時保存できないデータです',
    clientMessage: '一時保存できないデータです',
  },
  {
    appCode: AppCode.e46,
    code: ErrorCode.BadUserInput,
    message: '評価の送信ができないデータです',
    clientMessage: '評価の送信ができないデータです',
  },
  {
    appCode: AppCode.e47,
    code: ErrorCode.BadUserInput,
    message: '評価の修正ができないデータです',
    clientMessage: '評価の修正ができないデータです',
  },
  {
    appCode: AppCode.e48,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e49,
    code: ErrorCode.BadUserInput,
    message: '削除できない面接情報です',
    clientMessage: '削除できない面接情報です',
  },
  {
    appCode: AppCode.e50,
    code: ErrorCode.BadUserInput,
    message: '削除権限がありません',
    clientMessage: '削除権限がありません',
  },
  {
    appCode: AppCode.e51,
    code: ErrorCode.BadUserInput,
    message: '日程の開始と終了が逆転しています',
    clientMessage: '日程の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.e52,
    code: ErrorCode.BadUserInput,
    message: '日程調整が必要な面接がありません',
    clientMessage: '日程調整が必要な面接がありません',
  },
  {
    appCode: AppCode.e53,
    code: ErrorCode.BadUserInput,
    message: '登録権限がありません',
    clientMessage: '登録権限がありません',
  },
  {
    appCode: AppCode.e54,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e55,
    code: ErrorCode.BadUserInput,
    message: '編集権限がありません',
    clientMessage: '編集権限がありません',
  },
  {
    appCode: AppCode.e56,
    code: ErrorCode.BadUserInput,
    message: '既に日程が選択されています',
    clientMessage: '既に日程が選択されています',
  },
  {
    appCode: AppCode.e57,
    code: ErrorCode.BadUserInput,
    message: '最新面接の合否が決定していないエントリーは <#評価#> と <#課題#> は使用できません',
    clientMessage: '最新面接の合否が決定していないエントリーは <#評価#> と <#課題#> は使用できません',
  },
  {
    appCode: AppCode.e58,
    code: ErrorCode.BadUserInput,
    message: '最新面接の合否が決定していないエントリーを含む場合は <#評価#> と <#課題#> は使用できません',
    clientMessage: '最新面接の合否が決定していないエントリーを含む場合は <#評価#> と <#課題#> は使用できません',
  },
  {
    appCode: AppCode.e59,
    code: ErrorCode.BadUserInput,
    message: '最新面接の評価を入力していないエントリーを含む場合は <#評価#> は使用できません',
    clientMessage: '最新面接の評価を入力していないエントリーを含む場合は <#評価#> は使用できません',
  },
  {
    appCode: AppCode.e60,
    code: ErrorCode.BadUserInput,
    message: '最新面接の評価を入力していないエントリーは <#評価#> は使用できません',
    clientMessage: '最新面接の評価を入力していないエントリーは <#評価#> は使用できません',
  },
  {
    appCode: AppCode.e61,
    code: ErrorCode.BadUserInput,
    message: '最新面接の課題を入力していないエントリーは <#課題#> は使用できません',
    clientMessage: '最新面接の課題を入力していないエントリーは <#課題#> は使用できません',
  },
  {
    appCode: AppCode.e62,
    code: ErrorCode.BadUserInput,
    message: '最新面接の課題を入力していないエントリーを含む場合は <#課題#> は使用できません',
    clientMessage: '最新面接の課題を入力していないエントリーを含む場合は <#課題#> は使用できません',
  },
  {
    appCode: AppCode.e63,
    code: ErrorCode.BadUserInput,
    message: 'ファイルサイズが制限を超えています',
    clientMessage: 'ファイルサイズが制限を超えています',
  },
  {
    appCode: AppCode.e64,
    code: ErrorCode.BadUserInput,
    message: '掲載停止できないポジションが含まれています',
    clientMessage: '掲載停止できないポジションが含まれています',
  },
  {
    appCode: AppCode.e65,
    code: ErrorCode.BadUserInput,
    message: 'エンティティが存在しません',
    clientMessage: 'エンティティが存在しません',
  },
  {
    appCode: AppCode.e66,
    code: ErrorCode.BadUserInput,
    message: 'ファイルサイズの制限を超えています',
    clientMessage: 'ファイルサイズの制限を超えています',
  },
  {
    appCode: AppCode.e67,
    code: ErrorCode.BadUserInput,
    message: '無効なお知らせです',
    clientMessage: '無効なお知らせです',
  },
  {
    appCode: AppCode.e68,
    code: ErrorCode.BadUserInput,
    message: '不正なお知らせIDが含まれています',
    clientMessage: '不正なお知らせIDが含まれています',
  },
  {
    appCode: AppCode.e69,
    code: ErrorCode.BadUserInput,
    message: '編集権限が無いお知らせIDが含まれています',
    clientMessage: '編集権限が無いお知らせIDが含まれています',
  },
  {
    appCode: AppCode.e70,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.e71,
    code: ErrorCode.BadUserInput,
    message: '出力形式に対応していないエントリーIDが含まれています',
    clientMessage: '出力形式に対応していないエントリーIDが含まれています',
  },
  {
    appCode: AppCode.e72,
    code: ErrorCode.BadUserInput,
    message:
      'ファイルが存在しません。保存期間が過ぎ削除された可能性があります。再度ダウンロードから実行しなおしてください。',
    clientMessage:
      'ファイルが存在しません。保存期間が過ぎ削除された可能性があります。再度ダウンロードから実行しなおしてください。',
  },
  {
    appCode: AppCode.e73,
    code: ErrorCode.ForbiddenError,
    message: '許可されたIPアドレスではないためダウンロードできません。社内ネットワークからダウンロードしてください。',
    clientMessage:
      '許可されたIPアドレスではないためダウンロードできません。社内ネットワークからダウンロードしてください。',
  },
  {
    appCode: AppCode.e74,
    code: ErrorCode.BadUserInput,
    message: '雇用区分が異なるためエントリーできません',
    clientMessage: '雇用区分が異なるためエントリーできません',
  },
  {
    appCode: AppCode.e75,
    code: ErrorCode.BadUserInput,
    message: '別のポジションにエントリー中のため、エントリーできません',
    clientMessage: '別のポジションにエントリー中のため、エントリーできません',
  },
  {
    appCode: AppCode.e76,
    code: ErrorCode.BadUserInput,
    message: 'エントリーしたユーザーを承認者に設定することはできません',
    clientMessage: 'エントリーしたユーザーを承認者に設定することはできません',
  },
  {
    appCode: AppCode.e77,
    code: ErrorCode.BadUserInput,
    message: 'エントリーしたユーザーを面接官に設定することはできません',
    clientMessage: 'エントリーしたユーザーを面接官に設定することはできません',
  },
  {
    appCode: AppCode.e78,
    code: ErrorCode.ForbiddenError,
    message: '許可されたIPアドレスではないためアップロードできません。社内ネットワークからアップロードしてください。',
    clientMessage:
      '許可されたIPアドレスではないためアップロードできません。社内ネットワークからアップロードしてください。',
  },
  {
    appCode: AppCode.e79,
    code: ErrorCode.BadUserInput,
    message: '人事メモを作成する権限がありません。',
    clientMessage: '人事メモを作成する権限がありません。',
  },
  {
    appCode: AppCode.e80,
    code: ErrorCode.BadUserInput,
    message: '指定された面接のIDが不正です。',
    clientMessage: '指定された面接のIDが不正です。',
  },
  {
    appCode: AppCode.e81,
    code: ErrorCode.BadUserInput,
    message: '指定された面接データの編集権限がありません。',
    clientMessage: '指定された面接データの編集権限がありません。',
  },
  {
    appCode: AppCode.e82,
    code: ErrorCode.BadUserInput,
    message: '検証URLが無効です。',
    clientMessage: '検証URLが無効です。',
  },
  {
    appCode: AppCode.e83,
    code: ErrorCode.BadUserInput,
    message: '検証メールの有効期限切れです。',
    clientMessage: '検証メールの有効期限切れです。検証メールを再送信して再度検証を行ってください。',
  },
  {
    appCode: AppCode.e84,
    code: ErrorCode.BadUserInput,
    message: 'メールアドレスが変更されています。',
    clientMessage: 'メールアドレスが変更されています。',
  },
  {
    appCode: AppCode.e85,
    code: ErrorCode.BadUserInput,
    message: 'メールアドレスが設定されていません。',
    clientMessage: 'メールアドレスが設定されていません。',
  },
  {
    appCode: AppCode.e86,
    code: ErrorCode.BadUserInput,
    message: '既に検証済みです。',
    clientMessage: '既に検証済みです。',
  },
  {
    appCode: AppCode.e87,
    code: ErrorCode.BadUserInput,
    message: 'ファイルが存在しません。',
    clientMessage: 'ファイルが存在しません。',
  },
  {
    appCode: AppCode.e88,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みのポジションのため更新できません',
    clientMessage: 'エントリー済みのポジションのため更新できません',
  },
  {
    appCode: AppCode.e89,
    code: ErrorCode.BadUserInput,
    message: '承認者が設定されていません',
    clientMessage: '承認者が設定されていません',
  },
  {
    appCode: AppCode.e90,
    code: ErrorCode.BadUserInput,
    message: '指定された選考ステップと現在の選考ステップが異なります',
    clientMessage: '指定された選考ステップと現在の選考ステップが異なります',
  },
  {
    appCode: AppCode.e91,
    code: ErrorCode.BadUserInput,
    message: '指定された選考ステップの面接が存在しません',
    clientMessage: '指定された選考ステップの面接が存在しません',
  },
  {
    appCode: AppCode.e92,
    code: ErrorCode.BadUserInput,
    message: '指定された選考ステップの合否が決定しています',
    clientMessage: '指定された選考ステップの合否が決定しています',
  },
  {
    appCode: AppCode.e93,
    code: ErrorCode.BadUserInput,
    message: '指定された面接担当者が存在しません',
    clientMessage: '指定された面接担当者が存在しません',
  },
  {
    appCode: AppCode.e94,
    code: ErrorCode.BadUserInput,
    message: '指定された面接担当者が重複しています',
    clientMessage: '指定された面接担当者が重複しています',
  },
  {
    appCode: AppCode.e95,
    code: ErrorCode.BadUserInput,
    message: '既に終了した面接です',
    clientMessage: '既に終了した面接です',
  },
  {
    appCode: AppCode.e96,
    code: ErrorCode.BadUserInput,
    message: '正しい承認者が設定されていません',
    clientMessage: '正しい承認者が設定されていません',
  },
  {
    appCode: AppCode.e97,
    code: ErrorCode.BadUserInput,
    message: '削除されたユーザーを面接官に設定することはできません',
    clientMessage: '削除されたユーザーを面接官に設定することはできません',
  },
  {
    appCode: AppCode.e98,
    code: ErrorCode.BadUserInput,
    message: '削除されたユーザーは承認を実行できません',
    clientMessage: '削除されたユーザーは承認を実行できません',
  },
  {
    appCode: AppCode.e99,
    code: ErrorCode.BadUserInput,
    message: '削除されたまたは権限のないユーザーは担当者に設定できません',
    clientMessage: '削除されたまたは権限のないユーザーは担当者に設定できません',
  },
  {
    appCode: AppCode.e100,
    code: ErrorCode.BadUserInput,
    message: '削除されたまたは権限のないユーザーは問い合わせ先担当者に設定できません',
    clientMessage: '削除されたまたは権限のないユーザーは問い合わせ先担当者に設定できません',
  },
  {
    appCode: AppCode.e101,
    code: ErrorCode.BadUserInput,
    message: '掲載中でないお知らせは掲載停止できません',
    clientMessage: '掲載中でないお知らせは掲載停止できません',
  },
  {
    appCode: AppCode.e102,
    code: ErrorCode.BadUserInput,
    message: '無効な年間スケジュールです',
    clientMessage: '無効な年間スケジュールです',
  },
  {
    appCode: AppCode.e103,
    code: ErrorCode.BadUserInput,
    message: '今期または来期の年間スケジュールを指定してください',
    clientMessage: '今期または来期の年間スケジュールを指定してください',
  },
  {
    appCode: AppCode.e104,
    code: ErrorCode.BadUserInput,
    message: '不正な年間スケジュールが含まれています',
    clientMessage: '不正な年間スケジュールが含まれています',
  },
  {
    appCode: AppCode.e105,
    code: ErrorCode.BadUserInput,
    message: '不正なファイルキーが含まれています',
    clientMessage: '不正なファイルキーが含まれています',
  },
  {
    appCode: AppCode.e106,
    code: ErrorCode.BadUserInput,
    message: '指定されたファイルの署名付きURLは発行できません',
    clientMessage: '指定されたファイルの署名付きURLは発行できません',
  },
  {
    appCode: AppCode.e107,
    code: ErrorCode.BadUserInput,
    message: '不正なファイルキーが指定されています',
    clientMessage: '不正なファイルキーが指定されています',
  },
  {
    appCode: AppCode.e108,
    code: ErrorCode.BadUserInput,
    message: `ジョブトライアルのエントリーは${Object.keys(ElectionPriority).length}件まで可能です`,
    clientMessage: `ジョブトライアルのエントリーは${Object.keys(ElectionPriority).length}件まで可能です`,
  },
  {
    appCode: AppCode.e109,
    code: ErrorCode.BadUserInput,
    message: `${ElectionStatusName[ElectionStatus.RequirementsRejected]}、${
      ElectionStatusName[ElectionStatus.Rejected]
    }、${ElectionStatusName[ElectionStatus.Declined]}の選出ステータスは変更できません`,
    clientMessage: `${ElectionStatusName[ElectionStatus.RequirementsRejected]}、${
      ElectionStatusName[ElectionStatus.Rejected]
    }、${ElectionStatusName[ElectionStatus.Declined]}の選出ステータスは変更できません`,
  },
  {
    appCode: AppCode.e110,
    code: ErrorCode.BadUserInput,
    message: 'ポジションの残枠が不足しています',
    clientMessage: 'ポジションの残枠が不足しています',
  },
  {
    appCode: AppCode.e111,
    code: ErrorCode.BadUserInput,
    message: '今期以外のエントリーの選出ステータスの変更できません',
    clientMessage: '今期以外のエントリーの選出ステータスの変更できません',
  },
  {
    appCode: AppCode.e112,
    code: ErrorCode.BadUserInput,
    message: '今期のJTエントリーのelectionIdを全て指定してください',
    clientMessage: '今期のJTエントリーのelectionIdを全て指定してください',
  },
  {
    appCode: AppCode.e113,
    code: ErrorCode.BadUserInput,
    message: '応募期間外のエントリーが含まれています',
    clientMessage: '応募期間外のエントリーが含まれています',
  },
  {
    appCode: AppCode.e114,
    code: ErrorCode.BadUserInput,
    message: '希望順位が変更不可能なステップのエントリーが含まれています',
    clientMessage: '希望順位が変更不可能なステップのエントリーが含まれています',
  },
  {
    appCode: AppCode.e115,
    code: ErrorCode.BadUserInput,
    message: '今期のJTポジション以外のpositionIdが含まれています',
    clientMessage: '今期のJTポジション以外のpositionIdが含まれています',
  },
  {
    appCode: AppCode.e116,
    code: ErrorCode.BadUserInput,
    message: '前の希望のエントリーに未選出ステータスが存在するため自動選出できません',
    clientMessage: '前の希望のエントリーに未選出ステータスが存在するため自動選出できません',
  },
  {
    appCode: AppCode.e117,
    code: ErrorCode.BadUserInput,
    message: '指定希望の未選出エントリーの候補者に他希望の選出エントリーが存在するため自動選出できません',
    clientMessage: '指定希望の未選出エントリーの候補者に他希望の選出エントリーが存在するため自動選出できません',
  },
  {
    appCode: AppCode.e118,
    code: ErrorCode.BadUserInput,
    message: 'すでにユーザーに紐づくJTエントリーが3件以上存在するため選考ステップを否認から変更できません',
    clientMessage: 'すでにユーザーに紐づくJTエントリーが3件以上存在するため選考ステップを否認から変更できません',
  },
  {
    appCode: AppCode.e119,
    code: ErrorCode.BadUserInput,
    message: '選出エントリー数以上の定員数を指定してください',
    clientMessage: '選出エントリー数以上の定員数を指定してください',
  },
  {
    appCode: AppCode.e120,
    code: ErrorCode.BadUserInput,
    message: '研修種別名が重複しています',
    clientMessage: '研修種別名が重複しています',
  },
  {
    appCode: AppCode.e121,
    code: ErrorCode.BadUserInput,
    message: '無効な研修種別が含まれています',
    clientMessage: '無効な研修種別が含まれています',
  },
  {
    appCode: AppCode.e122,
    code: ErrorCode.BadUserInput,
    message: '研修日程が存在する研修種別は削除できません',
    clientMessage: '研修日程が存在する研修種別は削除できません',
  },
  {
    appCode: AppCode.e123,
    code: ErrorCode.BadUserInput,
    message: '研修日程の開始と終了が逆転しています',
    clientMessage: '研修日程の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.e124,
    code: ErrorCode.BadUserInput,
    message: '研修日程の開始と終了が期間内でありません',
    clientMessage: '研修日程の開始と終了が期間内でありません',
  },
  {
    appCode: AppCode.e125,
    code: ErrorCode.BadUserInput,
    message: '存在しないIdが含まれています',
    clientMessage: '存在しないIdが含まれています',
  },
  {
    appCode: AppCode.e126,
    code: ErrorCode.BadUserInput,
    message: '既にエントリーが存在する研修日程が含まれています',
    clientMessage: '既にエントリーが存在する研修日程が含まれています',
  },
  {
    appCode: AppCode.e127,
    code: ErrorCode.BadUserInput,
    message: 'periodはYYYYMM形式で入力してください',
    clientMessage: '期間の形式が誤っています',
  },
  {
    appCode: AppCode.e128,
    code: ErrorCode.BadUserInput,
    message: '無効な研修エントリーが含まれています',
    clientMessage: '無効な研修エントリーが含まれています',
  },
  {
    appCode: AppCode.e129,
    code: ErrorCode.BadUserInput,
    message: 'isWatchedVideo、worksheetFileのどちらかの入力が必要です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e130,
    code: ErrorCode.BadUserInput,
    message: 'periodはYYYYMM形式で入力してください',
    clientMessage: '期間の形式が誤っています',
  },
  {
    appCode: AppCode.e131,
    code: ErrorCode.BadUserInput,
    message: '不正なworkshopTypeが指定されました',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.e132,
    code: ErrorCode.BadUserInput,
    message: 'ワークシートにXLSX以外のファイルが指定されました',
    clientMessage: 'ワークシートのフォーマットが不正です',
  },
  {
    appCode: AppCode.e133,
    code: ErrorCode.BadUserInput,
    message: '変更可能なメールアドレスがありません',
    clientMessage: '変更可能なメールアドレスがありません',
  },
  {
    appCode: AppCode.e134,
    code: ErrorCode.BadUserInput,
    message: '無効な研修エントリーです',
    clientMessage: '無効な研修エントリーです',
  },
  {
    appCode: AppCode.e135,
    code: ErrorCode.BadUserInput,
    message: '第1希望日のスケジュール情報が登録されていません',
    clientMessage: '第1希望日のスケジュール情報が登録されていません',
  },
  {
    appCode: AppCode.e136,
    code: ErrorCode.BadUserInput,
    message: '第2希望日のスケジュール情報が登録されていません',
    clientMessage: '第2希望日のスケジュール情報が登録されていません',
  },
  {
    appCode: AppCode.e137,
    code: ErrorCode.BadUserInput,
    message: '第3希望日のスケジュール情報が登録されていません',
    clientMessage: '第3希望日のスケジュール情報が登録されていません',
  },
  {
    appCode: AppCode.e138,
    code: ErrorCode.BadUserInput,
    message: '参加日のスケジュール情報が登録されていません',
    clientMessage: '参加日のスケジュール情報が登録されていません',
  },
  {
    appCode: AppCode.e139,
    code: ErrorCode.Internal,
    message: '競合が発生しました。もう一度やり直してください。',
    clientMessage: '競合が発生しました。もう一度やり直してください。',
  },
  {
    appCode: AppCode.e140,
    code: ErrorCode.BadUserInput,
    message: 'デフォルトのタグのIDが含まれています。デフォルトのタグは削除できません。',
    clientMessage: 'デフォルトのタグのIDが含まれています。デフォルトのタグは削除できません。',
  },
  {
    appCode: AppCode.e141,
    code: ErrorCode.BadUserInput,
    message: '存在しないタグIDが含まれています',
    clientMessage: '存在しないタグIDが含まれています',
  },
  {
    appCode: AppCode.e142,
    code: ErrorCode.BadUserInput,
    message: '差し戻しエントリーを一時保存することはできません',
    clientMessage: '差し戻しエントリーを一時保存することはできません',
  },
  {
    appCode: AppCode.e143,
    code: ErrorCode.BadUserInput,
    message: '差し戻し理由が入力されていません',
    clientMessage: '差し戻し理由が入力されていません',
  },
  {
    appCode: AppCode.e144,
    code: ErrorCode.BadUserInput,
    message: '1候補者につき1エントリーしか内定承諾できません',
    clientMessage: '1候補者につき1エントリーしか内定承諾できません',
  },
  {
    appCode: AppCode.e145,
    code: ErrorCode.BadUserInput,
    message: '1つのエントリーしか承諾できません',
    clientMessage: '1つのエントリーしか承諾できません',
  },
  {
    appCode: AppCode.e146,
    code: ErrorCode.BadUserInput,
    message: '打診できないエントリーです',
    clientMessage: '打診できないエントリーです',
  },
  {
    appCode: AppCode.e147,
    code: ErrorCode.BadUserInput,
    message: '打診できないポジションです',
    clientMessage: '打診できないポジションです',
  },
  {
    appCode: AppCode.e148,
    code: ErrorCode.BadUserInput,
    message: 'すでにエントリー済みのポジションです',
    clientMessage: 'すでにエントリー済みのポジションです',
  },
  {
    appCode: AppCode.e149,
    code: ErrorCode.BadUserInput,
    message: '公開されていないポジションです。',
    clientMessage: '公開されていないポジションです。',
  },
  {
    appCode: AppCode.e150,
    code: ErrorCode.BadUserInput,
    message: '無効なexternalIdが含まれています',
    clientMessage: '無効なexternalIdが含まれています',
  },
  {
    appCode: AppCode.e151,
    code: ErrorCode.BadUserInput,
    message: '権限がありません',
    clientMessage: '権限がありません',
  },
  {
    appCode: AppCode.e152,
    code: ErrorCode.BadUserInput,
    message: 'すでに要対応フラグが設定されています',
    clientMessage: 'すでに要対応フラグが設定されています',
  },
  {
    appCode: AppCode.e153,
    code: ErrorCode.BadUserInput,
    message: 'すでに要対応フラグが解除されています',
    clientMessage: 'すでに要対応フラグが解除されています',
  },
  {
    appCode: AppCode.e154,
    code: ErrorCode.BadUserInput,
    message: '無効なワークシート履歴です',
    clientMessage: '無効なワークシート履歴です',
  },
  {
    appCode: AppCode.e155,
    code: ErrorCode.BadUserInput,
    message: '履歴名が重複しています',
    clientMessage: '履歴名が重複しています',
  },
  {
    appCode: AppCode.e156,
    code: ErrorCode.BadUserInput,
    message: '履歴に紐づく研修が存在するため削除できません',
    clientMessage: '履歴に紐づく研修が存在するため削除できません',
  },
  {
    appCode: AppCode.e157,
    code: ErrorCode.BadUserInput,
    message: '無効な研修エントリーIDが指定されています',
    clientMessage: '無効な研修エントリーIDが指定されています',
  },
  {
    appCode: AppCode.e158,
    code: ErrorCode.BadUserInput,
    message: '無効なワークシートIDが指定されています',
    clientMessage: '無効なワークシートIDが指定されています',
  },
  {
    appCode: AppCode.e159,
    code: ErrorCode.BadUserInput,
    message: 'widthの和が不正です',
    clientMessage: 'widthの和が不正です',
  },
  {
    appCode: AppCode.e160,
    code: ErrorCode.BadUserInput,
    message: 'すでにURLが公開されています',
    clientMessage: 'すでにURLが公開されています',
  },
  {
    appCode: AppCode.e161,
    code: ErrorCode.BadUserInput,
    message: `送信できるメッセージは${MaxDmMessageLength}字までです`,
    clientMessage: `送信できるメッセージは${MaxDmMessageLength}字までです`,
  },
  {
    appCode: AppCode.e162,
    code: ErrorCode.BadUserInput,
    message: 'selfで指定したメッセージが存在しません',
    clientMessage: 'メッセージが存在しません',
  },
  {
    appCode: AppCode.needToReplaceDoublework1,
    code: ErrorCode.BadUserInput,
    message: '無効な複業案件が含まれています',
    clientMessage: '無効な複業案件が含まれています',
  },
  {
    appCode: AppCode.needToReplaceDoublework2,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みの複業案件は削除できません',
    clientMessage: 'エントリー済みの複業案件は削除できません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkSuspend,
    code: ErrorCode.BadUserInput,
    message: '掲載停止できない複業案件が含まれています',
    clientMessage: '掲載停止できない複業案件が含まれています',
  },
  {
    appCode: AppCode.bulkExportDoubleworks1,
    code: ErrorCode.BadUserInput,
    message: '無効な案件が含まれています',
    clientMessage: '無効な案件が含まれています',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate1,
    code: ErrorCode.BadUserInput,
    message: '複業案件作成権限がありません',
    clientMessage: '複業案件作成権限がありません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate2,
    code: ErrorCode.BadUserInput,
    message: '公開期間の開始と終了が逆転しています',
    clientMessage: '公開期間の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate3,
    code: ErrorCode.BadUserInput,
    message: '応募期間の開始と終了が逆転しています',
    clientMessage: '応募期間の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate4,
    code: ErrorCode.BadUserInput,
    message: '契約期間の開始と終了が逆転しています',
    clientMessage: '契約期間の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate5,
    code: ErrorCode.BadUserInput,
    message: '削除されたまたは権限のないユーザーは担当者に設定できません',
    clientMessage: '削除されたまたは権限のないユーザーは担当者に設定できません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate6,
    code: ErrorCode.BadUserInput,
    message: '削除されたまたは権限のないユーザーは問い合わせ先担当者に設定できません',
    clientMessage: '削除されたまたは権限のないユーザーは問い合わせ先担当者に設定できません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate7,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが未指定です',
    clientMessage: '誓約バージョンが未指定です',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate8,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが異なります',
    clientMessage: '誓約バージョンが異なります',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate9,
    code: ErrorCode.BadUserInput,
    message: '作業範囲に「その他」が選択されていますが内容が入力されていません',
    clientMessage: '作業範囲に「その他」が選択されていますが内容が入力されていません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate10,
    code: ErrorCode.BadUserInput,
    message: '削除されたまたは権限のないユーザーは受入責任者に設定できません',
    clientMessage: '削除されたまたは権限のないユーザーは受入責任者に設定できません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate11,
    code: ErrorCode.BadUserInput,
    message: '設定可能な委託業務大分類を入力してください',
    clientMessage: '設定可能な委託業務大分類を入力してください',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate12,
    code: ErrorCode.BadUserInput,
    message: '設定可能な委託業務中分類を入力してください',
    clientMessage: '設定可能な委託業務中分類を入力してください',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate13,
    code: ErrorCode.BadUserInput,
    message: '設定可能な委託業務小分類を入力してください',
    clientMessage: '設定可能な委託業務小分類を入力してください',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate14,
    code: ErrorCode.BadUserInput,
    message: '委託業務小分類の「すべて」を選択した場合は他の項目を選択することはできません',
    clientMessage: '委託業務小分類の「すべて」を選択した場合は他の項目を選択することはできません',
  },
  {
    appCode: AppCode.needToReplaceDoubleworkCreate15,
    code: ErrorCode.BadUserInput,
    message: '委託業務大分類が未選択の場合は委託業務中分類を選択することはできません',
    clientMessage: '委託業務大分類が未選択の場合は委託業務中分類を選択することはできません',
  },
  {
    appCode: AppCode.updateDoublework1,
    code: ErrorCode.BadUserInput,
    message: '複業案件更新権限がありません',
    clientMessage: '複業案件更新権限がありません',
  },
  {
    appCode: AppCode.updateDoublework2,
    code: ErrorCode.BadUserInput,
    message: '承認済み複業案件の更新権限がありません',
    clientMessage: '承認済み複業案件の更新権限がありません',
  },
  {
    appCode: AppCode.updateDoublework3,
    code: ErrorCode.BadUserInput,
    message: '承認済み複業案件のstatusは変更できません',
    clientMessage: '承認済み複業案件のstatusは変更できません',
  },
  {
    appCode: AppCode.updateDoublework4,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みの複業案件のため更新できません',
    clientMessage: 'エントリー済みの複業案件のため更新できません',
  },
  {
    appCode: AppCode.updateDoublework5,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが未指定です',
    clientMessage: '誓約バージョンが未指定です',
  },
  {
    appCode: AppCode.updateDoublework6,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが異なります',
    clientMessage: '誓約バージョンが異なります',
  },
  {
    appCode: AppCode.doubleworkNeedToVideoCompleted,
    code: ErrorCode.BadUserInput,
    message: 'ページをリロードして最新のリテラシー動画をご覧ください',
    clientMessage: 'ページをリロードして最新のリテラシー動画をご覧ください',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry1,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが未指定です',
    clientMessage: '誓約バージョンが未指定です',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry2,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが異なります',
    clientMessage: '誓約バージョンが異なります',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry3,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みの複業案件です',
    clientMessage: 'エントリー済みの複業案件です',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry4,
    code: ErrorCode.BadUserInput,
    message: '複業案件の応募期間外です',
    clientMessage: '複業案件の応募期間外です',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry5,
    code: ErrorCode.BadUserInput,
    message: '別の複業案件にエントリー中のため、エントリーできません',
    clientMessage: '別の複業案件にエントリー中のため、エントリーできません',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry6,
    code: ErrorCode.BadUserInput,
    message: 'エントリーの編集権限がありません',
    clientMessage: 'エントリーの編集権限がありません',
  },
  {
    appCode: AppCode.needToReplaceDeleteDoubleworkEntry1,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.needToReplaceGetDateFromYearString1,
    code: ErrorCode.BadUserInput,
    message: 'yearはYYYY形式で入力してください',
    clientMessage: '年度の形式が誤っています',
  },
  {
    appCode: AppCode.needToReplacebulkExportDoubleworkEntries1,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry8,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkEntry9,
    code: ErrorCode.BadUserInput,
    message: 'エントリーの参照権限がありません。',
    clientMessage: 'エントリーの参照権限がありません。',
  },
  {
    appCode: AppCode.createDoubleworkEntry10,
    code: ErrorCode.BadUserInput,
    message: '掲載停止案件であるため、エントリーできません',
    clientMessage: '掲載停止案件であるため、エントリーできません',
  },
  {
    appCode: AppCode.needToReplaceUpdateEntriesFlowStep1,
    code: ErrorCode.BadUserInput,
    message: '無効なエントリーが含まれています',
    clientMessage: '無効なエントリーが含まれています',
  },
  {
    appCode: AppCode.needToReplaceUpdateEntriesFlowStep2,
    code: ErrorCode.BadUserInput,
    message: '変更先の選考ステップが存在しません',
    clientMessage: '変更先の選考ステップが存在しません',
  },
  {
    appCode: AppCode.needToReplaceUpdateEntriesFlowStep4,
    code: ErrorCode.BadUserInput,
    message: '権限がないためステップ変更できません',
    clientMessage: '権限がないためステップ変更できません',
  },
  {
    appCode: AppCode.createDoubleworkEntryNote1,
    code: ErrorCode.BadUserInput,
    message: '選考メモを作成する権限がありません',
    clientMessage: '選考メモを作成する権限がありません',
  },
  {
    appCode: AppCode.updateDoubleworkEntryIsDecided1,
    code: ErrorCode.BadUserInput,
    message: '存在しないエントリーです',
    clientMessage: '存在しないエントリーです',
  },
  {
    appCode: AppCode.updateDoubleworkEntryIsDecided2,
    code: ErrorCode.BadUserInput,
    message: '候補者決定フラグを更新する権限がありません',
    clientMessage: '候補者決定フラグを更新する権限がありません',
  },
  {
    appCode: AppCode.needToReplaceAssignSelector1,
    code: ErrorCode.BadUserInput,
    message: '選考担当者の設定権限がありません',
    clientMessage: '選考担当者の設定権限がありません',
  },
  {
    appCode: AppCode.needToReplaceAssignSelector2,
    code: ErrorCode.BadUserInput,
    message: '選考担当者が重複しています',
    clientMessage: '選考担当者が重複しています',
  },
  {
    appCode: AppCode.needToReplaceAssignSelector3,
    code: ErrorCode.BadUserInput,
    message: '選考担当者に設定できないユーザーが含まれています',
    clientMessage: '選考担当者に設定できないユーザーが含まれています',
  },
  {
    appCode: AppCode.needToReplaceAssignSelector4,
    code: ErrorCode.BadUserInput,
    message: 'エントリーしたユーザーを選考担当者に設定することはできません',
    clientMessage: 'エントリーしたユーザーを選考担当者に設定することはできません',
  },
  {
    appCode: AppCode.needToReplaceAssignSelector5,
    code: ErrorCode.BadUserInput,
    message: '選考ステップを変更できないエントリー情報です',
    clientMessage: '選考ステップを変更できないエントリー情報です',
  },
  {
    appCode: AppCode.needToReplacebulkCreateDoubleworkEntryMessages1,
    code: ErrorCode.BadUserInput,
    message: 'メッセージ作成権限がないエントリーIDが含まれています',
    clientMessage: 'メッセージ作成権限がないエントリーIDが含まれています',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkInterviewSchedule1,
    code: ErrorCode.BadUserInput,
    message: '日程の開始と終了が逆転しています',
    clientMessage: '日程の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkInterviewSchedule2,
    code: ErrorCode.BadUserInput,
    message: '日程調整が必要な面談がありません',
    clientMessage: '日程調整が必要な面談がありません',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkInterviewSchedule3,
    code: ErrorCode.BadUserInput,
    message: '登録権限がありません',
    clientMessage: '登録権限がありません',
  },
  {
    appCode: AppCode.needToReplaceCreateDoubleworkInterviewSchedule4,
    code: ErrorCode.BadUserInput,
    message: '日程登録できない選考ステップです',
    clientMessage: '日程登録できない選考ステップです',
  },
  {
    appCode: AppCode.needToReplaceSelectInterviewScheduleDate1,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.needToReplaceSelectInterviewScheduleDate2,
    code: ErrorCode.BadUserInput,
    message: '日程選択できない選考ステップです',
    clientMessage: '日程選択できない選考ステップです',
  },
  {
    appCode: AppCode.needToReplaceSelectInterviewScheduleDate3,
    code: ErrorCode.BadUserInput,
    message: '編集権限がありません',
    clientMessage: '編集権限がありません',
  },
  {
    appCode: AppCode.needToReplaceSelectInterviewScheduleDate4,
    code: ErrorCode.BadUserInput,
    message: '既に日程が選択されています',
    clientMessage: '既に日程が選択されています',
  },
  {
    appCode: AppCode.doubleworkEntryMessages1,
    code: ErrorCode.BadUserInput,
    message: 'before,after,selfを指定する場合は、いずれか1つを指定してください',
    clientMessage: 'before,after,selfを指定する場合は、いずれか1つを指定してください',
  },
  {
    appCode: AppCode.doubleworkEntryMessages2,
    code: ErrorCode.BadUserInput,
    message: 'selfで指定したメッセージが存在しません',
    clientMessage: 'メッセージが存在しません',
  },
  {
    appCode: AppCode.needToReplaceCheckCanEntry1,
    code: ErrorCode.ForbiddenError,
    message: 'グループ内複業案件の応募権限がありません',
    clientMessage: 'グループ内複業案件の応募権限がありません',
  },
  {
    appCode: AppCode.needToReplaceCheckCanEntry2,
    code: ErrorCode.ForbiddenError,
    message: '所属している会社の複業案件へはエントリーできません',
    clientMessage: '所属している会社の複業案件へはエントリーできません',
  },
  {
    appCode: AppCode.doubleworkCheckCanEntry,
    code: ErrorCode.ForbiddenError,
    message: 'SBU管掌案件であるため所属している会社と同じSBU案件へはエントリーできません',
    clientMessage: 'SBU管掌案件であるため所属している会社と同じSBU案件へはエントリーできません',
  },
  {
    appCode: AppCode.doubleworkEntryNeedToVideoCompleted,
    code: ErrorCode.ForbiddenError,
    message: 'ページをリロードして最新のリテラシー動画をご覧ください',
    clientMessage: 'ページをリロードして最新のリテラシー動画をご覧ください',
  },
  {
    appCode: AppCode.e163,
    code: ErrorCode.ForbiddenError,
    message: 'loginAttemptExceeded',
    clientMessage: 'loginAttemptExceeded',
  },
  {
    appCode: AppCode.e164,
    code: ErrorCode.BadUserInput,
    message: '今期はすでにエントリー済みです',
    clientMessage: '今期はすでにエントリー済みです',
  },
  {
    appCode: AppCode.e165,
    code: ErrorCode.BadUserInput,
    message: 'アンケートの必須項目が入力されていません',
    clientMessage: 'アンケートの必須項目が入力されていません',
  },
  {
    appCode: AppCode.e166,
    code: ErrorCode.BadUserInput,
    message: 'アンケートの入力が不正です',
    clientMessage: 'アンケートの入力が不正です',
  },
  {
    appCode: AppCode.e167,
    code: ErrorCode.BadUserInput,
    message: '同一研修種別内で参加希望日程を指定してください',
    clientMessage: '同一研修種別内で参加希望日程を指定してください',
  },
  {
    appCode: AppCode.e168,
    code: ErrorCode.ForbiddenError,
    message: '応募期間外です',
    clientMessage: '応募期間外です',
  },
  {
    appCode: AppCode.e169,
    code: ErrorCode.BadUserInput,
    message: '参加における考慮事項が入力されていません',
    clientMessage: '参加における考慮事項が入力されていません',
  },
  {
    appCode: AppCode.e170,
    code: ErrorCode.BadUserInput,
    message: 'アンケートの入力が不正です',
    clientMessage: 'アンケートの入力が不正です',
  },
  {
    appCode: AppCode.e171,
    code: ErrorCode.BadUserInput,
    message: '対象のエントリーが存在しません',
    clientMessage: '対象のエントリーが存在しません',
  },
  {
    appCode: AppCode.e172,
    code: ErrorCode.BadUserInput,
    message: '今期ではないスケジュールが含まれています',
    clientMessage: '今期ではないスケジュールが含まれています',
  },
  {
    appCode: AppCode.e173,
    code: ErrorCode.BadUserInput,
    message: '既にアンケートに回答済みです',
    clientMessage: '既にアンケートに回答済みです',
  },
  {
    appCode: AppCode.e174,
    code: ErrorCode.BadUserInput,
    message: 'アンケートの入力が不正です',
    clientMessage: 'アンケートの入力が不正です',
  },
  {
    appCode: AppCode.e175,
    code: ErrorCode.BadUserInput,
    message: '対象のエントリーが存在しません',
    clientMessage: '対象のエントリーが存在しません',
  },
  {
    appCode: AppCode.e176,
    code: ErrorCode.BadUserInput,
    message: '今期ではないスケジュールが含まれています',
    clientMessage: '今期ではないスケジュールが含まれています',
  },
  {
    appCode: AppCode.e177,
    code: ErrorCode.BadUserInput,
    message: 'アンケートの必須項目が入力されていません',
    clientMessage: 'アンケートの必須項目が入力されていません',
  },
  {
    appCode: AppCode.e178,
    code: ErrorCode.BadUserInput,
    message: '既にアンケートに回答済みです',
    clientMessage: '既にアンケートに回答済みです',
  },
  {
    appCode: AppCode.e179,
    code: ErrorCode.BadUserInput,
    message: '年齢に不正な値が入力されています',
    clientMessage: '年齢に不正な値が入力されています',
  },
  {
    appCode: AppCode.e180,
    code: ErrorCode.BadUserInput,
    message: '既に参加日程登録済み、または今期ではないエントリーが含まれています',
    clientMessage: '既に参加日程登録済み、または今期ではないエントリーが含まれています',
  },
  {
    appCode: AppCode.e181,
    code: ErrorCode.BadUserInput,
    message: '今期ではないスケジュールが含まれています',
    clientMessage: '今期ではないスケジュールが含まれています',
  },
  {
    appCode: AppCode.e182,
    code: ErrorCode.BadUserInput,
    message: '複数の対象が含まれています',
    clientMessage: '複数の対象が含まれています',
  },
  {
    appCode: AppCode.e183,
    code: ErrorCode.BadUserInput,
    message: '未設定の件数と更新件数が異なっています',
    clientMessage: '未設定の件数と更新件数が異なっています',
  },
  {
    appCode: AppCode.e184,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーが含まれています',
    clientMessage: '不正なエントリーが含まれています',
  },
  {
    appCode: AppCode.e185,
    code: ErrorCode.BadUserInput,
    message: '不正なスケジュールが含まれています',
    clientMessage: '不正なスケジュールが含まれています',
  },
  {
    appCode: AppCode.e186,
    code: ErrorCode.BadUserInput,
    message: 'リセット対象のエントリーがありません',
    clientMessage: 'リセット対象のエントリーがありません',
  },
  {
    appCode: AppCode.e187,
    code: ErrorCode.BadUserInput,
    message: '既に開催済のエントリーが含まれています',
    clientMessage: '既に開催済のエントリーが含まれています',
  },
  {
    appCode: AppCode.e188,
    code: ErrorCode.BadUserInput,
    message: 'periodが重複しています',
    clientMessage: 'periodが重複しています',
  },
  {
    appCode: AppCode.e189,
    code: ErrorCode.BadUserInput,
    message: '無効な研修マスター情報が含まれています',
    clientMessage: '無効な研修マスター情報が含まれています',
  },
  {
    appCode: AppCode.e190,
    code: ErrorCode.BadUserInput,
    message: '存在しないユーザーです',
    clientMessage: '存在しないユーザーです',
  },
  {
    appCode: AppCode.e191,
    code: ErrorCode.BadUserInput,
    message: 'すでにエントリー済みのユーザーです',
    clientMessage: 'すでにエントリー済みのユーザーです',
  },
  {
    appCode: AppCode.e192,
    code: ErrorCode.BadUserInput,
    message: '不正な研修日程です',
    clientMessage: '不正な研修日程です',
  },
  {
    appCode: AppCode.updateDoubleworkEntryProcedures1,
    code: ErrorCode.BadUserInput,
    message: 'チェック対象の入力が不正です',
    clientMessage: 'チェック対象の入力が不正です',
  },
  {
    appCode: AppCode.updateDoubleworkEntryProcedures2,
    code: ErrorCode.BadUserInput,
    message: 'チェック対象のkeyが存在しません',
    clientMessage: 'チェック対象のkeyが存在しません',
  },
  {
    appCode: AppCode.updateDoubleworkEntryProcedures3,
    code: ErrorCode.BadUserInput,
    message: 'チェックがすでに変更されています',
    clientMessage: 'チェックがすでに変更されています',
  },
  {
    appCode: AppCode.updateDoubleworkEntryProcedures4,
    code: ErrorCode.ForbiddenError,
    message: '手続き詳細の更新権限がありません',
    clientMessage: '手続き詳細の更新権限がありません',
  },
  {
    appCode: AppCode.e193,
    code: ErrorCode.BadUserInput,
    message: '不正なバージョン情報です',
    clientMessage: '不正なバージョン情報です',
  },
  {
    appCode: AppCode.e194,
    code: ErrorCode.BadUserInput,
    message: 'エクスポート対象のアンケートがありません',
    clientMessage: 'エクスポート対象のアンケートがありません',
  },
  {
    appCode: AppCode.needToReplaceCrm191_001,
    code: ErrorCode.BadUserInput,
    message: '開催期と公開期間を同時に指定することはできません',
    clientMessage: '開催期と公開期間を同時に指定することはできません',
  },
  {
    appCode: AppCode.e195,
    code: ErrorCode.ForbiddenError,
    message: 'エントリーの参照権限がありません。',
    clientMessage: 'エントリーの参照権限がありません。',
  },
  {
    appCode: AppCode.updateDoubleworkEntryMonitoring1,
    code: ErrorCode.ForbiddenError,
    message: 'モニタリング操作権限がありません',
    clientMessage: 'モニタリング操作権限がありません',
  },
  {
    appCode: AppCode.updateDoubleworkEntryMonitoring2,
    code: ErrorCode.BadUserInput,
    message: 'モニタリング操作できないエントリーです',
    clientMessage: 'モニタリング操作できないエントリーです',
  },
  {
    appCode: AppCode.updateDoubleworkEntryMonitoring3,
    code: ErrorCode.BadUserInput,
    message: 'モニタリング操作できない選考ステップです',
    clientMessage: 'モニタリング操作できない選考ステップです',
  },
  {
    appCode: AppCode.updateDoubleworkEntryDialogClosedUserId1,
    code: ErrorCode.ForbiddenError,
    message: '操作権限がありません',
    clientMessage: '操作権限がありません',
  },
  {
    appCode: AppCode.updateDoubleworkEntryDialogClosedUserId2,
    code: ErrorCode.BadUserInput,
    message: '操作できない選考ステップです',
    clientMessage: '操作できない選考ステップです',
  },
  {
    appCode: AppCode.uniqApplicationDocumentNumber,
    code: ErrorCode.BadUserInput,
    message: '過去のエントリーに紐づいた書類番号は登録できません',
    clientMessage: '過去のエントリーに紐づいた書類番号は登録できません',
  },
  {
    appCode: AppCode.updateUserResume1,
    code: ErrorCode.ForbiddenError,
    message: '他のユーザのプロフィールは更新できません',
    clientMessage: '他のユーザのプロフィールは更新できません',
  },
  {
    appCode: AppCode.updateUserResume2,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には居住地の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には居住地の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume3,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には生年月日の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には生年月日の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume4,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には年収の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には年収の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume5,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には紹介文の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には紹介文の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume6,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には自己PRの入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には自己PRの入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume7,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には今後のキャリアの展望の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には今後のキャリアの展望の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume8,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には希望職種の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には希望職種の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume9,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には希望勤務地の入力が必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には希望勤務地の入力が必要です',
  },
  {
    appCode: AppCode.updateUserResume10,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
  },
  {
    appCode: AppCode.updateUserResume11,
    code: ErrorCode.BadUserInput,
    message: 'プロフィール登録期間外のため、スカウト設定を変更できません',
    clientMessage: 'プロフィール登録期間外のため、スカウト設定を変更できません',
  },
  {
    appCode: AppCode.updateUserResume12,
    code: ErrorCode.BadUserInput,
    message: 'G職でないため、グループスカウトを許可できません',
    clientMessage: 'G職でないため、グループスカウトを許可できません',
  },
  {
    appCode: AppCode.updateUserResume13,
    code: ErrorCode.BadUserInput,
    message: '原籍会社が個社内スカウトを実施していないため、個社内スカウトを許可できません',
    clientMessage: '原籍会社が個社内スカウトを実施していないため、個社内スカウトを許可できません',
  },
  {
    appCode: AppCode.updateUserResume14,
    code: ErrorCode.BadUserInput,
    message: '非公開個社が正しくありません',
    clientMessage: '非公開個社が正しくありません',
  },
  {
    appCode: AppCode.updateUserResume15,
    code: ErrorCode.BadUserInput,
    message: '非公開社員が正しくありません',
    clientMessage: '非公開社員が正しくありません',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeCareer1,
    code: ErrorCode.BadUserInput,
    message: '期間の開始と終了が逆転しています',
    clientMessage: '期間の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeCareer2,
    code: ErrorCode.BadUserInput,
    message: '対象の「経歴/学歴」が存在しません',
    clientMessage: '対象の「経歴/学歴」が存在しません',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeCareer3,
    code: ErrorCode.BadUserInput,
    message: '「活動・実績」の活動期間を先に変更してください',
    clientMessage: '「活動・実績」の活動期間を先に変更してください',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeCareer4,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
  },
  {
    appCode: AppCode.deleteUserResumeCareer1,
    code: ErrorCode.BadUserInput,
    message: '対象の「経歴/学歴」が存在しません',
    clientMessage: '対象の「経歴/学歴」が存在しません',
  },
  {
    appCode: AppCode.deleteUserResumeCareer2,
    code: ErrorCode.BadUserInput,
    message: '対象の「プロフィール」が存在しません',
    clientMessage: '対象の「プロフィール」が存在しません',
  },
  {
    appCode: AppCode.deleteUserResumeCareer3,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
    clientMessage: 'グループスカウト・個社内スカウトの許可には経歴に職歴/学歴の入力がそれぞれ１件以上必要です',
  },
  {
    appCode: AppCode.userResumeCareerActivity1,
    code: ErrorCode.BadUserInput,
    message: '活動期間の開始と終了が逆転しています',
    clientMessage: '活動期間の開始と終了が逆転しています',
  },
  {
    appCode: AppCode.userResumeCareerActivity2,
    code: ErrorCode.BadUserInput,
    message: '活動期間を「経歴/学歴」の範囲外に設定することはできません',
    clientMessage: '活動期間を「経歴/学歴」の範囲外に設定することはできません',
  },
  {
    appCode: AppCode.userResumeCareerActivity3,
    code: ErrorCode.BadUserInput,
    message: '活動期間を未来に設定することはできません',
    clientMessage: '活動期間を未来に設定することはできません',
  },
  {
    appCode: AppCode.userResumeCareerActivity4,
    code: ErrorCode.BadUserInput,
    message: '対象の「経歴/学歴」が存在しません',
    clientMessage: '対象の「経歴/学歴」が存在しません',
  },
  {
    appCode: AppCode.userResumeCareerActivity5,
    code: ErrorCode.BadUserInput,
    message: '対象の「活動・実績」が存在しません',
    clientMessage: '対象の「活動・実績」が存在しません',
  },
  {
    appCode: AppCode.userResumeCareerActivity6,
    code: ErrorCode.BadUserInput,
    message: 'youTubeUrlが正しくありません',
    clientMessage: 'youTubeUrlが正しくありません',
  },
  {
    appCode: AppCode.deleteSkills1,
    code: ErrorCode.BadUserInput,
    message: 'デフォルトのスキルのIDが含まれています。デフォルトのスキルは削除できません。',
    clientMessage: 'デフォルトのスキルのIDが含まれています。デフォルトのスキルは削除できません。',
  },
  {
    appCode: AppCode.deleteSkills2,
    code: ErrorCode.BadUserInput,
    message: '存在しないスキルIDが含まれています',
    clientMessage: '存在しないスキルIDが含まれています',
  },
  {
    appCode: AppCode.updateUserResumeSkill1,
    code: ErrorCode.BadUserInput,
    message: '他のユーザのプロフィールのスキルは更新できません',
    clientMessage: '他のユーザのプロフィールのスキルは更新できません',
  },
  {
    appCode: AppCode.updateUserResumeSkill2,
    code: ErrorCode.BadUserInput,
    message: '無効なスキルが含まれています',
    clientMessage: '無効なスキルが含まれています',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeAchievement1,
    code: ErrorCode.BadUserInput,
    message: '対象の「プロフィール」がありません',
    clientMessage: '対象の「プロフィール」がありません',
  },
  {
    appCode: AppCode.createOrUpdateUserResumeAchievement2,
    code: ErrorCode.BadUserInput,
    message: '対象の「その他の実績」がありません',
    clientMessage: '対象の「その他の実績」がありません',
  },
  {
    appCode: AppCode.deleteUserResumeAchievement1,
    code: ErrorCode.BadUserInput,
    message: '対象の「その他の実績」がありません',
    clientMessage: '対象の「その他の実績」がありません',
  },
  {
    appCode: AppCode.userResumeQualificationGrade1,
    code: ErrorCode.BadUserInput,
    message: '登録できない資格等級です',
    clientMessage: '登録できない資格等級です',
  },
  {
    appCode: AppCode.userResumeQualificationGrade2,
    code: ErrorCode.BadUserInput,
    message: 'スコアを入力してください',
    clientMessage: 'スコアを入力してください',
  },
  {
    appCode: AppCode.userResumeQualificationGrade3,
    code: ErrorCode.BadUserInput,
    message: 'スコアが入力できない資格等級です',
    clientMessage: 'スコアが入力できない資格等級です',
  },
  {
    appCode: AppCode.userResumeQualificationGrade4,
    code: ErrorCode.BadUserInput,
    message: 'すでに登録済みの資格等級です',
    clientMessage: 'すでに登録済みの資格等級です',
  },
  {
    appCode: AppCode.userResumeQualificationGrade5,
    code: ErrorCode.BadUserInput,
    message: '対象の「資格」が存在しません',
    clientMessage: '対象の「資格」が存在しません',
  },
  {
    appCode: AppCode.updateWorkshopEntryWorksheet1,
    code: ErrorCode.BadUserInput,
    message: '今期のエントリーには最新バージョンのワークシートしか紐付けできません。',
    clientMessage: '今期のエントリーには最新バージョンのワークシートしか紐付けできません。',
  },
  {
    appCode: AppCode.getFilterUserResumes1,
    code: ErrorCode.BadUserInput,
    message: 'isOpenToGroupScoutかisOpenToIndividualScoutどちらかはtrueで指定してください',
    clientMessage: 'isOpenToGroupScoutかisOpenToIndividualScoutどちらかはtrueで指定してください',
  },
  {
    appCode: AppCode.getFilterUserResumes2,
    code: ErrorCode.BadUserInput,
    message: '権限の範囲には権限を持たない個社は指定できません',
    clientMessage: '権限の範囲には権限を持たない個社は指定できません',
  },
  {
    appCode: AppCode.careerScoutsForbidden,
    code: ErrorCode.BadUserInput,
    message: '無効なポジションが含まれています',
    clientMessage: '無効なポジションが含まれています',
  },
  {
    appCode: AppCode.suspendCareerScouts,
    code: ErrorCode.BadUserInput,
    message: '掲載停止できないポジションが含まれています',
    clientMessage: '掲載停止できないポジションが含まれています',
  },
  {
    appCode: AppCode.deleteCareerScouts1,
    code: ErrorCode.BadUserInput,
    message: 'オファーまたはエントリー済みのポジションは削除できません',
    clientMessage: 'オファーまたはエントリー済みのポジションは削除できません',
  },
  {
    appCode: AppCode.deleteCareerScouts2,
    code: ErrorCode.ForbiddenError,
    message: '削除権限の無いポジションが含まれています',
    clientMessage: '削除権限の無いポジションが含まれています',
  },
  {
    appCode: AppCode.createCareerScout1,
    code: ErrorCode.BadUserInput,
    message: '半期のポジション承認制限を超えています',
    clientMessage: '半期のポジション承認制限を超えています',
  },
  {
    appCode: AppCode.createCareerScout2,
    code: ErrorCode.BadUserInput,
    message: '権限が無いため作成、更新できないステータスです',
    clientMessage: '権限が無いため作成、更新できないステータスです',
  },
  {
    appCode: AppCode.createCareerScout3,
    code: ErrorCode.BadUserInput,
    message: '個社スカウトが許可されていないため、個社公開できません',
    clientMessage: '個社スカウトが許可されていません、個社公開できません',
  },
  {
    appCode: AppCode.updateCareerScout1,
    code: ErrorCode.BadUserInput,
    message: 'ポジション更新権限がありません',
    clientMessage: 'ポジション更新権限がありません',
  },
  {
    appCode: AppCode.updateCareerScout2,
    code: ErrorCode.BadUserInput,
    message: '承認済ポジションの更新権限がありません',
    clientMessage: '承認済ポジションの更新権限がありません',
  },
  {
    appCode: AppCode.updateCareerScout3,
    code: ErrorCode.BadUserInput,
    message: '承認済ポジションのstatusは変更できません',
    clientMessage: '承認済ポジションのstatusは変更できません',
  },
  {
    appCode: AppCode.updateCareerScout4,
    code: ErrorCode.BadUserInput,
    message: 'オファーまたはエントリー済みのポジションのため、変更できません',
    clientMessage: 'オファーまたはエントリー済みのポジションのため、変更できません',
  },
  {
    appCode: AppCode.markAsReadCareerScoutOfferMessage1,
    code: ErrorCode.BadUserInput,
    message: '無効なスカウトオファーメッセージIDです',
    clientMessage: '無効なスカウトオファーメッセージIDです',
  },
  {
    appCode: AppCode.toggleCareerScoutOfferSenderUserFlag1,
    code: ErrorCode.BadUserInput,
    message: '対象のオファーが存在しない、または更新権限がありません',
    clientMessage: '対象のオファーが存在しない、または更新権限がありません',
  },
  {
    appCode: AppCode.toggleCareerScoutOfferRecipientUserFlag1,
    code: ErrorCode.BadUserInput,
    message: '対象のオファーが存在しない、または更新権限がありません',
    clientMessage: '対象のオファーが存在しない、または更新権限がありません',
  },
  {
    appCode: AppCode.deleteCareerScoutOfferMessage1,
    code: ErrorCode.BadUserInput,
    message: '削除できないオファーメッセージIDです',
    clientMessage: '削除できないオファーメッセージIDです',
  },
  {
    appCode: AppCode.createOrUpdateCareerScoutOfferAndMessage1,
    code: ErrorCode.BadUserInput,
    message: '一時保存または未承認のポジションです',
    clientMessage: '一時保存または未承認のポジションです',
  },
  {
    appCode: AppCode.createOrUpdateCareerScoutOfferAndMessage2,
    code: ErrorCode.BadUserInput,
    message: 'メッセージの作成権限がありません',
    clientMessage: 'メッセージの作成権限がありません',
  },
  {
    appCode: AppCode.createOrUpdateCareerScoutOfferAndMessage3,
    code: ErrorCode.BadUserInput,
    message: '該当するオファーがありません',
    clientMessage: '該当するオファーがありません',
  },
  {
    appCode: AppCode.createOrUpdateCareerScoutOfferAndMessage4,
    code: ErrorCode.BadUserInput,
    message: '既にスカウトメッセージの下書きが存在しています',
    clientMessage: '既にスカウトメッセージの下書きが存在しています',
  },
  {
    appCode: AppCode.createOrUpdateCareerScoutOfferAndMessage5,
    code: ErrorCode.BadUserInput,
    message: '該当するスカウトメッセージがありません',
    clientMessage: '該当するスカウトメッセージがありません',
  },
  {
    appCode: AppCode.checkCanOffer1,
    code: ErrorCode.BadUserInput,
    message: 'オファー期間ではないためオファーできません',
    clientMessage: 'オファー期間ではないためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer2,
    code: ErrorCode.BadUserInput,
    message: '権限がないポジションであるためオファーできません',
    clientMessage: '権限がないポジションであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer3,
    code: ErrorCode.BadUserInput,
    message: '承認済ポジションではないためオファーできません',
    clientMessage: '承認済ポジションではないためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer4,
    code: ErrorCode.BadUserInput,
    message: 'キャリアチャレンジにエントリー済みのユーザであるためオファーできません',
    clientMessage: 'キャリアチャレンジにエントリー済みのユーザであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer5,
    code: ErrorCode.BadUserInput,
    message: 'スカウト権限がないためオファーできません',
    clientMessage: 'スカウト権限がないためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer6,
    code: ErrorCode.BadUserInput,
    message: '個社公開されていないポジションであるためオファーできません',
    clientMessage: '個社公開されていないポジションであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer7,
    code: ErrorCode.BadUserInput,
    message: 'グループ公開されていないポジションであるためオファーできません',
    clientMessage: 'グループ公開されていないポジションであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer8,
    code: ErrorCode.BadUserInput,
    message: '個社スカウトが許可されていないためオファーできません',
    clientMessage: '個社スカウトが許可されていないためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer9,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウトが許可されていないためオファーできません',
    clientMessage: 'グループスカウトが許可されていないためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer10,
    code: ErrorCode.BadUserInput,
    message: 'ポジションの雇用区分外のユーザであるためオファーできません',
    clientMessage: 'ポジションの雇用区分外のユーザであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer11,
    code: ErrorCode.BadUserInput,
    message: '既にオファー済みであるためオファーできません',
    clientMessage: '既にオファー済みであるためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer12,
    code: ErrorCode.BadUserInput,
    message: 'ポジションのオファーの送信上限数に達しているためオファーできません',
    clientMessage: 'ポジションのオファーの送信上限数に達しているためオファーできません',
  },
  {
    appCode: AppCode.checkCanOffer13,
    code: ErrorCode.BadUserInput,
    message: '別のポジションに内定(異動)承諾しているユーザであるためオファーできません',
    clientMessage: '別のポジションに内定(異動)承諾しているユーザであるためオファーできません',
  },
  {
    appCode: AppCode.updateUserResumesScoutSetting1,
    code: ErrorCode.BadUserInput,
    message: '存在しない社員番号が含まれています',
    clientMessage: '存在しない社員番号が含まれています',
  },
  {
    appCode: AppCode.updateUserResumesScoutSetting2,
    code: ErrorCode.BadUserInput,
    message: '非公開個社が正しくありません',
    clientMessage: '非公開個社が正しくありません',
  },
  {
    appCode: AppCode.updateUserResumesScoutSetting3,
    code: ErrorCode.BadUserInput,
    message: '非公開社員が正しくありません',
    clientMessage: '非公開社員が正しくありません',
  },
  {
    appCode: AppCode.careerScoutTemplate1,
    code: ErrorCode.BadUserInput,
    message: 'テンプレート名が重複しています',
    clientMessage: 'テンプレート名が重複しています',
  },
  {
    appCode: AppCode.careerScoutTemplate2,
    code: ErrorCode.ForbiddenError,
    message: 'テンプレート更新権限がありません',
    clientMessage: 'テンプレート更新権限がありません',
  },
  {
    appCode: AppCode.careerScoutTemplate3,
    code: ErrorCode.ForbiddenError,
    message: '削除権限の無いテンプレートが含まれています',
    clientMessage: '削除権限の無いテンプレートが含まれています',
  },
  {
    appCode: AppCode.careerScoutTemplate4,
    code: ErrorCode.ForbiddenError,
    message: 'テンプレート作成権限がありません',
    clientMessage: 'テンプレート作成権限がありません',
  },
  {
    appCode: AppCode.createCareerScoutEntry1,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが未指定です',
    clientMessage: '誓約バージョンが未指定です',
  },
  {
    appCode: AppCode.createCareerScoutEntry2,
    code: ErrorCode.BadUserInput,
    message: '誓約バージョンが異なります',
    clientMessage: '誓約バージョンが異なります',
  },
  {
    appCode: AppCode.createCareerScoutEntry3,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みのキャリアスカウト案件です',
    clientMessage: 'エントリー済みのキャリアスカウト案件です',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry2,
    code: ErrorCode.BadUserInput,
    message: 'ポジションの応募期間外です',
    clientMessage: 'ポジションの応募期間外です',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry3,
    code: ErrorCode.BadUserInput,
    message: 'ポジションが掲載停止中です',
    clientMessage: 'ポジションが掲載停止中です',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry4,
    code: ErrorCode.BadUserInput,
    message: 'ポジション公開範囲外となったためエントリーできません',
    clientMessage: 'ポジション公開範囲外となったためエントリーできません',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry5,
    code: ErrorCode.BadUserInput,
    message: '雇用区分が範囲外です',
    clientMessage: '雇用区分が範囲外です',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry6,
    code: ErrorCode.BadUserInput,
    message: 'エントリー済みのキャリアスカウト案件です',
    clientMessage: 'エントリー済みのキャリアスカウト案件です',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry7,
    code: ErrorCode.BadUserInput,
    message: '他のエントリーが内定・異動承諾まで進んでいるためエントリーできません',
    clientMessage: '他のエントリーが内定・異動承諾まで進んでいるためエントリーできません',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry8,
    code: ErrorCode.BadUserInput,
    message: 'キャリアチャレンジにエントリー済みのためエントリーできません',
    clientMessage: 'キャリアチャレンジにエントリー済みのためエントリーできません',
  },
  {
    appCode: AppCode.careerScoutCheckCanEntry9,
    code: ErrorCode.BadUserInput,
    message: 'グループスカウト・個社内スカウトの区分がオファー時と異なるためエントリーできません',
    clientMessage: 'グループスカウト・個社内スカウトの区分がオファー時と異なるためエントリーできません',
  },
  {
    appCode: AppCode.deleteCareerScoutEntries1,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.positionCheckCanEntry1,
    code: ErrorCode.BadUserInput,
    message: 'キャリアスカウトにエントリー済みのためエントリーできません',
    clientMessage: 'キャリアスカウトにエントリー済みのためエントリーできません',
  },
  {
    appCode: AppCode.careerScoutEntryForbidden,
    code: ErrorCode.ForbiddenError,
    message: 'エントリーの参照権限がありません',
    clientMessage: 'エントリーの参照権限がありません',
  },
  {
    appCode: AppCode.updateCareerScoutEntriesFlowStep1,
    code: ErrorCode.BadUserInput,
    message: '無効なエントリーが含まれています',
    clientMessage: '無効なエントリーが含まれています',
  },
  {
    appCode: AppCode.updateCareerScoutEntriesFlowStep2,
    code: ErrorCode.BadUserInput,
    message: '変更先の選考ステップが存在しません',
    clientMessage: '変更先の選考ステップが存在しません',
  },
  {
    appCode: AppCode.updateCareerScoutEntriesFlowStep3,
    code: ErrorCode.BadUserInput,
    message: '面接が完了していないエントリーが含まれています',
    clientMessage: '面接が完了していないエントリーが含まれています',
  },
  {
    appCode: AppCode.updateCareerScoutEntriesFlowStep4,
    code: ErrorCode.BadUserInput,
    message: '1候補者につき1エントリーしか内定承諾できません',
    clientMessage: '1候補者につき1エントリーしか内定承諾できません',
  },
  {
    appCode: AppCode.updateCareerScoutEntriesFlowStep5,
    code: ErrorCode.BadUserInput,
    message: '不正なステップ変更です',
    clientMessage: '不正なステップ変更です',
  },
  {
    appCode: AppCode.bulkExportCareerScoutEntries1,
    code: ErrorCode.ForbiddenError,
    message: '許可されたIPアドレスではないためダウンロードできません。社内ネットワークからダウンロードしてください。',
    clientMessage:
      '許可されたIPアドレスではないためダウンロードできません。社内ネットワークからダウンロードしてください。',
  },
  {
    appCode: AppCode.bulkExportCareerScoutEntries2,
    code: ErrorCode.BadUserInput,
    message: '不正なエントリーIDが含まれています',
    clientMessage: '不正なエントリーIDが含まれています',
  },
  {
    appCode: AppCode.bulkExportCareerScoutEntries3,
    code: ErrorCode.BadUserInput,
    message: '出力形式に対応していないエントリーIDが含まれています',
    clientMessage: '出力形式に対応していないエントリーIDが含まれています',
  },
  {
    appCode: AppCode.badParameter,
    code: ErrorCode.BadUserInput,
    message: 'パラメータが不正です',
    clientMessage: 'パラメータが不正です',
  },
  {
    appCode: AppCode.updateAccountAdminUsers1,
    code: ErrorCode.BadUserInput,
    message: '無効なユーザーが含まれています',
    clientMessage: '無効なユーザーが含まれています',
  },
  {
    appCode: AppCode.updateAccountAdminUsers2,
    code: ErrorCode.BadUserInput,
    message: 'すでにグループ管理者権限を持っているユーザーは追加できません',
    clientMessage: 'すでにグループ管理者権限を持っているユーザーは追加できません',
  },
  {
    appCode: AppCode.createOrUpdateOrganizationAdminUsers1,
    code: ErrorCode.BadUserInput,
    message: 'すでに対象の個社管理者権限を持っているユーザーは追加できません',
    clientMessage: 'すでに対象の個社管理者権限を持っているユーザーは追加できません',
  },
  {
    appCode: AppCode.createOrUpdateOrganizationAdminUsers2,
    code: ErrorCode.BadUserInput,
    message: '対象の個社管理者権限を持っていないユーザーは更新できません',
    clientMessage: '対象の個社管理者権限を持っていないユーザーは更新できません',
  },
  {
    appCode: AppCode.createOrUpdateOrganizationAdminUsers3,
    code: ErrorCode.BadUserInput,
    message: '対象のユーザーが存在しません',
    clientMessage: '対象のユーザーが存在しません',
  },
  {
    appCode: AppCode.createOrUpdateOrganizationAdminUsers4,
    code: ErrorCode.BadUserInput,
    message: '対象の会社が存在しません',
    clientMessage: '対象の会社が存在しません',
  },
  {
    appCode: AppCode.bulkExportOrganizationAdminUsers1,
    code: ErrorCode.BadUserInput,
    message: '無効なIDが含まれています',
    clientMessage: '無効なIDが含まれています',
  },
  {
    appCode: AppCode.userLoadLock,
    code: ErrorCode.ForbiddenError,
    message: 'ロック中のため実行できません',
    clientMessage: 'ロック中のため実行できません',
  },
  {
    appCode: AppCode.incomeDetail1,
    code: ErrorCode.BadUserInput,
    message: '給与区分を選択してください',
    clientMessage: '給与区分を選択してください',
  },
  {
    appCode: AppCode.incomeDetail2,
    code: ErrorCode.BadUserInput,
    message: '月給制の場合は年収の詳細項目を入力してください',
    clientMessage: '月給制の場合は年収の詳細項目を入力してください',
  },
  {
    appCode: AppCode.autoAssignWorkshopEntriesGroup1,
    code: ErrorCode.BadUserInput,
    message: 'エントリー数が0、1、2、5のためグループを自動割り当てできません',
    clientMessage: 'エントリー数が0、1、2、5のためグループを自動割り当てできません',
  },
  {
    appCode: AppCode.autoAssignWorkshopEntriesGroup2,
    code: ErrorCode.BadUserInput,
    message: '4人グループ数より時短エントリーが多いためグループを自動割り当てできません',
    clientMessage: '4人グループ数より時短エントリーが多いためグループを自動割り当てできません',
  },
  {
    appCode: AppCode.autoAssignWorkshopEntriesGroup3,
    code: ErrorCode.BadUserInput,
    message: '会社・部署の重複のため4人グループを自動割り当てできません',
    clientMessage: '会社・部署の重複のため4人グループを自動割り当てできません',
  },
  {
    appCode: AppCode.autoAssignWorkshopEntriesGroup4,
    code: ErrorCode.BadUserInput,
    message: '会社・部署の重複のため3人グループを自動割り当てできません',
    clientMessage: '会社・部署の重複のため3人グループを自動割り当てできません',
  },
  {
    appCode: AppCode.e196,
    code: ErrorCode.BadUserInput,
    message: 'PositionTypeの変更はできません',
    clientMessage: 'PositionTypeの変更はできません',
  },
]
