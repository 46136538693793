import _ from 'lodash'
import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { SubscriptionClient, Middleware } from 'subscriptions-transport-ws'
import { apolloLocalStateCachePolicy, mutations } from './state'
import { isBrowser } from '../util'
import * as Sentry from '@sentry/nextjs'
import { GraphQLError } from 'graphql'

function createWsLink(): any {
  const uri = process.env.NEXT_PUBLIC_SUBSCRIPTION_ENDPOINT ?? ''
  const wsLink = new SubscriptionClient(uri, { reconnect: true })
  const subscriptionMiddleware: Middleware = {
    applyMiddleware(options, next) {
      mutations.checkToken().then(({ jwtToken }) => {
        options.headers = { authorization: jwtToken }
        next()
      })
    },
  }
  return wsLink.use([subscriptionMiddleware])
}

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const links: ApolloLink[] = []

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // NOTE: build後の本番環境で、下記処理がサーバサイドで実行されることはないが、開発環境では実行されるため、
    // サーバサイドではエラーログを残さないようにする
    if (!isBrowser) {
      return
    }

    if (graphQLErrors !== undefined) {
      graphQLErrors.forEach(err => {
        const { message, locations, path, extensions } = err
        if (extensions?.appCode === undefined) {
          Sentry.captureException(err instanceof GraphQLError ? err : new GraphQLError(message))
        }
        console.log(`[GraphQL error]: Message: ${message}`, 'locations:', locations, 'path:', path)
      })
    }
    if (networkError !== undefined) {
      Sentry.captureException(networkError)
      console.log('[Network error]: ', networkError)
    }
  })

  links.push(errorLink)

  const httpAuthLink = setContext(async (_, { headers }) => {
    const { jwtToken } = await mutations.checkToken()
    return {
      headers: {
        ...headers,
        authorization: jwtToken,
      },
    }
  })

  const httpLink = httpAuthLink.concat(
    (createUploadLink({
      uri: process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT,
      credentials: 'same-origin',
    }) as unknown) as ApolloLink,
  )

  // NOTE: build後の本番環境で、下記処理がサーバサイドで実行されることはないが、開発環境では実行されるため、
  // サーバサイドではWebSocketを使わないようにする
  if (isBrowser) {
    const wsLink = createWsLink()

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      httpLink,
    )
    links.push(splitLink)
  } else {
    links.push(httpLink)
  }

  return new ApolloClient({
    link: ApolloLink.from([...links]),
    cache: new InMemoryCache({
      typePolicies: {
        Video: {
          keyFields: ['fileKey'], // videoにidが無いので一意に識別するために`fileKey`を使用
        },
        Query: {
          fields: {
            // NOTE: positionsのcursorベースのpagenationに対応
            // NOTE: cache-policyをcache-and-networkやnetwork-onlyにすると動きがおかしい
            positions: {
              keyArgs(args) {
                return Object.keys(args ?? {}).filter(x => !['self', 'before', 'after', 'limit'].includes(x))
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || (args?.self !== null && args?.self !== undefined)) {
                  return incoming
                }
                const concated = !_.isEmpty(args?.before)
                  ? incoming.items.concat(existing?.items ?? [])
                  : (existing?.items ?? []).concat(incoming.items)
                const items = _.unionBy(concated, x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor = _.isEmpty(args?.before) ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  !_.isEmpty(args?.before) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { ...incoming, items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            doubleworks: {
              keyArgs(args) {
                return Object.keys(args ?? {}).filter(x => !['self', 'before', 'after', 'limit'].includes(x))
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || (args?.self !== null && args?.self !== undefined)) {
                  return incoming
                }
                const concated = !_.isEmpty(args?.before)
                  ? incoming.items.concat(existing?.items ?? [])
                  : (existing?.items ?? []).concat(incoming.items)
                const items = _.unionBy(concated, x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor = _.isEmpty(args?.before) ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  !_.isEmpty(args?.before) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { ...incoming, items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            userResumes: {
              keyArgs(args) {
                return Object.keys(args ?? {}).filter(x => !['self', 'before', 'after', 'limit'].includes(x))
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || (args?.self !== null && args?.self !== undefined)) {
                  return incoming
                }
                const concated = !_.isEmpty(args?.before)
                  ? incoming.items.concat(existing?.items ?? [])
                  : (existing?.items ?? []).concat(incoming.items)
                const items = _.unionBy(concated, x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor =
                  _.isEmpty(args?.before) && incoming.endCursor !== null ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  !_.isEmpty(args?.before) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { ...incoming, items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            templates: {
              merge: false,
            },
            entryMessages: {
              keyArgs() {
                return ['entryId']
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || args?.self !== null) {
                  return incoming
                }
                const items = _.unionBy((existing?.items ?? []).concat(incoming.items), x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor = _.isEmpty(args?.before) ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  (!_.isEmpty(args?.before) || !_.isEmpty(args?.self)) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            doubleworkEntryMessages: {
              keyArgs() {
                return ['entryId']
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || args?.self !== null) {
                  return incoming
                }
                const items = _.unionBy((existing?.items ?? []).concat(incoming.items), x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor = _.isEmpty(args?.before) ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  (!_.isEmpty(args?.before) || !_.isEmpty(args?.self)) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            careerScoutEntryMessages: {
              keyArgs() {
                return ['entryId']
              },
              merge(existing, incoming, { args, readField }) {
                if (existing === undefined || args?.self !== null) {
                  return incoming
                }
                const items = _.unionBy((existing?.items ?? []).concat(incoming.items), x => readField('id', x as any))
                const hasNext = incoming.hasNext !== null ? incoming.hasNext : existing?.hasNext
                const hasBefore = incoming.hasBefore !== null ? incoming.hasBefore : existing?.hasBefore
                const endCursor = _.isEmpty(args?.before) ? incoming.endCursor : existing?.endCursor
                const startCursor =
                  (!_.isEmpty(args?.before) || !_.isEmpty(args?.self)) && incoming.startCursor !== null
                    ? incoming.startCursor
                    : existing?.startCursor
                const resp = { items, hasNext, hasBefore, endCursor, startCursor }
                return resp
              },
            },
            entryMessageNotifications: {
              keyArgs() {
                return []
              },
              merge(existing, incoming, { readField }) {
                if (existing === undefined) {
                  return incoming
                }
                const items = _.sortBy(
                  _.unionBy((existing?.items ?? []).concat(incoming.items), x => readField('id', x as any)),
                  x => -Number(readField('id', x as any)),
                )
                const hasNext = incoming.hasNext
                const endCursor = incoming.endCursor
                const startCursor = existing?.startCursor ?? null
                const resp = { items, hasNext, endCursor, startCursor }
                return resp
              },
            },
            notifications: {
              keyArgs() {
                return []
              },
              merge(existing, incoming, { readField }) {
                if (existing === undefined) {
                  return incoming
                }
                const items = _.sortBy(
                  _.unionBy((existing?.items ?? []).concat(incoming.items), x => readField('id', x as any)),
                  x => -Number(readField('id', x as any)),
                )
                const hasNext = incoming.hasNext
                const endCursor = incoming.endCursor
                const startCursor = existing?.startCursor ?? null
                const resp = { items, hasNext, endCursor, startCursor }
                return resp
              },
            },
          },
        },
        LocalState: apolloLocalStateCachePolicy,
      },
    }),
  })
}

let apolloClient: ReturnType<typeof createApolloClient>

export function initializeApollo(): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient()

  // NOTE: build後の本番環境で、下記処理がサーバサイドで実行されることはない、SSG対応時にapolloClientを使う必要があるかは要検討
  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient
  // Create the Apollo Client once in the client
  if (apolloClient !== undefined) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(), [])
  return store
}
